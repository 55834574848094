import React, {useCallback, useEffect, useMemo, useRef, useState,} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setAppLoader, setFieldValue, setInputError, setSteps} from "../../../slices/slices.js";
import dayjs from "dayjs";
import {motion, useAnimation} from "framer-motion";
import {useInView} from "react-intersection-observer";
import {Label} from "/components/ui/label.jsx";
import errorSvg from "/assets/error.jpg";
import ElementComp from "../../components/ElementComponent/ElementComponent.jsx";
import TextInputComp from "../../components/TextInputComponent/TextInputComponent.jsx";
import TextOtpInput from "../../components/TextOtpInput/TextOtpInput.jsx";
import SelectComp from "../../components/SelectInputComponent/SelectInput.jsx";
import FileInputComp from "../../components/FileInputComponent/FileInputComponent.jsx";
import CaptureComponent from "../../components/CaptureComponent/CaptureComponent.jsx";
import DynamicForm from "../../components/FormInputComponent/FormInput.jsx";
import SignatureComponent from "../../components/SignatureComponent/Signature.jsx";
import {callNextEventAPI} from "../../../services/api/api.js";
import SelfieComponent from "../../components/SelfieComponent/SelfiComponent.jsx";
import {DateTimePicker,} from "../../components/DatePickerComponent/CustomeDatePickerCOmponent.jsx";
import Header from "../../components/Header/Header.jsx";
import FooterComp from "../../presets/preset1/components/FooterComp.jsx";
import FinalSuccessWizard from "../../components/SuccessScreen/SuccessScreen.jsx";
import WaitingScreen from "../../components/WaitingScreen/WaitingScreen.jsx";
import RedirectScreen from "../../components/RedirectComponent/RedirectComponent.jsx";
import ReversePennyDrop from "../../presets/preset1/screens/ReversePennyDrop.jsx";
import ListElement from "../../components/ListComponent/ListComponent.jsx";
import EsignRedirect from "/src/views/presets/preset1/screens/EsignRedirect.jsx";
import DigilockerRedirect from "/src/views/presets/preset1/screens/DigilockerRedirect.jsx"

export default function ProductionItem(props) {
    const {index, currentIndex} = props;
    const {
        interfaceSession,
        appLoader,
        step,
        isPrevious,
        components,
        replayComponentsData,
        traceData,
        progressState,
        socket,
        inputError
    } = useSelector((state) => state.userSlice);

    const [errorMessage, setErrorMessage] = useState("");
    const [element, setElement] = useState([])
    const control = useAnimation();
    const {ref, inView} = useInView({threshold: 0.5});

    const [showWaiting, setShowWaiting] = useState(false);

    const videoRef = useRef(null);
    const canvasRef = useRef(null);

    const cardVariants = useMemo(
        () => ({
            offscreen: {opacity: 0, x: 200},  // Start off-screen and invisible
            onscreen: {opacity: 1, x: 0},     // Fade in and come to the center
            exit: {opacity: 0, x: -200},
        }),
        [index]
    );

    const backCardVariants = useMemo(
        () => ({
            offscreen: {opacity: 0, x: -200}, // Start off-screen and invisible, from the other side
            onscreen: {opacity: 1, x: 0},     // Fade in and come to the center
            exit: {opacity: 0, x: 200},
        }),
        [index]
    );

    const dispatch = useDispatch();
    const responseSecond = useRef(0);
    const responseTimerRef = useRef();

    const handleInput = (e) => {
        let componentType;
        if (components?.[step]?.data?.message?.hasOwnProperty("interaction_type")) {
            componentType = components?.[step]?.data?.message?.interaction_type;
        } else {
            componentType = components?.[step]?.data?.message?.message_type;
        }
        const {value} = e.target;
        const tempArray = components?.map((item) => ({
            ...item,
            value: item.value || "",
            file: item.file || "",
        }));
        if (componentType === "NUMERIC_INPUT") {
            tempArray[step]["value"] = value?.replace(/[^0-9]/g, "");
        } else {
            tempArray[step]["value"] = value;
        }
        dispatch(setFieldValue(tempArray));
    };

    const handleNext = (payload) => {
        console.log("Payload", payload, components[step]?.data?.message?.interaction_type);
        setErrorMessage("");

        if (components[step]?.value || components[step]?.file || components[step]?.data?.message?.interaction_type === 'FORM_INPUT') {

            const blockId = components?.[step]?.data?.interface_block_id;

            if (blockId) {
                Object.assign(payload, {interface_block_id: blockId});
            }

            dispatch(setAppLoader(true));
            responseSecond.current = 0;
            socket.emit("interface_interaction", payload);
        }
    };

    const disabledField = useMemo(() => {
        let disabled = false;
        const startWidgetBlock = traceData.find(
            (item) => item?.data?.interface_block_type === "on_start_widget_block"
        );
        if (
            components?.[step]?.data?.interface_block_type ===
            "flash_message_widget_block" &&
            startWidgetBlock?.current_status !== "COMPLETED" &&
            startWidgetBlock?.current_status !== "FAILED"
        ) {
            disabled = false;
            return disabled;
        }
        const isStatusCompleted = traceData?.some(
            (data) =>
                data?.data?.interface_block_id ===
                components?.[step]?.data?.interface_block_id &&
                (data?.data?.message?.block_current_status === "COMPLETED" || data?.data?.message?.block_current_status === 'FAILED')
        );

        console.log("Is Status COmpleted", isStatusCompleted, components[step]?.value);
        if (isStatusCompleted) {
            disabled = true;
        }

        return disabled;
    }, [traceData, components, step]);

    function getHandlePending() {
        responseTimerRef.current = setInterval(() => {
            responseSecond.current += 1;
            if (responseSecond.current == 60) {
                console.log("responseSecond", responseSecond.current);
                responseSecond.current = 0;
                dispatch(setAppLoader(false));
                responseTimerRef.current = null;
                clearInterval(responseTimerRef.current);
                let redirectUrl = '';
                if (interfaceSession?.redirect_url?.includes('?')) {
                    redirectUrl = `${interfaceSession?.redirect_url}&status=pending`
                } else {
                    redirectUrl = `${
                        interfaceSession?.redirect_url
                    }/?status=pending`;
                }
                window.open(redirectUrl, "_self");
            }
        }, 1000);
    }

    useEffect(() => {
        if (appLoader) {
            responseSecond.current = 0;
            getHandlePending();
        }
    }, [appLoader, step]);

    useEffect(() => {
        if (components?.length !== element?.length) {
            // const surepassFilter = components?.filter(item => progressState?.some(state => state?.group == 'SUREPASS' || state?.group == 'WIDGETS.RECIEVE' || state?.block_type=='flash_message_widget_block'));
            setElement(components);
            clearInterval(responseTimerRef.current);
        }
        if (components?.[step]?.data?.message?.message_type == "TERMINATE") {

            if (interfaceSession?.redirect_url) {
                let redirectUrl = '';
                if (interfaceSession?.redirect_url?.includes('?')) {
                    redirectUrl = `${interfaceSession?.redirect_url}&status=completed`
                } else {
                    redirectUrl = `${
                        interfaceSession?.redirect_url
                    }/?status=completed`;
                }
                setTimeout(() => {
                    window.open(redirectUrl, "_self");
                }, 1000);
            }
        }
        const isStatusHalt = traceData?.some(
            (data) =>
                data?.data?.interface_block_type ===
                components?.[step]?.data?.interface_block_type &&
                data?.data?.message?.block_current_status === "FAILED"
        );

        if (
            isStatusHalt ||
            components?.[step]?.data?.message?.message_type == "HALT"
        ) {
            if (interfaceSession?.redirect_url) {
                let redirectUrl = '';
                if (interfaceSession?.redirect_url?.includes('?')) {
                    redirectUrl = `${interfaceSession?.redirect_url}&status=failed`
                } else {
                    redirectUrl = `${
                        interfaceSession?.redirect_url
                    }/?status=failed`;
                }

                setTimeout(() => {
                    window.open(redirectUrl, "_self");
                }, 1000);
            }
            return;
        }
    }, [index, currentIndex, components]);

    const renderValue = useCallback((interactionType, defaultValue, block_id) => {
        console.log("Default value", defaultValue);
        if (defaultValue) {

            return defaultValue;
        } else if (replayComponentsData.length) {
            const data = replayComponentsData.find(
                (item) =>
                    item.data.interaction_type == interactionType &&
                    item?.data?.interface_block_id == block_id
            );
            console.log("Data", data);
            if (
                interactionType == "FILE_INPUT" &&
                data?.data?.interaction_type == interactionType
            ) {
                let obj = {
                    image: `data:${data?.data?.content_type};base64,${data?.data?.data}`,
                    filename: data?.data?.filename,
                };

                return obj;
            } else if (
                interactionType == "NUMERIC_INPUT" &&
                data?.data?.interaction_type == interactionType
            ) {
                return data?.data?.value;
            } else if (
                interactionType == "TEXT_INPUT" &&
                data?.data?.interaction_type == interactionType
            ) {
                return data?.data?.text;
            } else if (
                interactionType == "OTP_INPUT" &&
                data?.data?.interaction_type == interactionType
            ) {
                return data?.data?.value;
            } else if (
                interactionType == "SELECT_INPUT" &&
                data?.data?.interaction_type == interactionType
            ) {
                return data?.data?.title;
            } else if (
                interactionType == "ELEMENT" &&
                data?.data?.interaction_type == interactionType
            ) {
                return data?.data?.text;
            } else if (
                interactionType == "START_BUTTON" &&
                data?.data?.interaction_type == interactionType
            ) {
                return true;
            } else if (
                interactionType == "CAMERA_IMAGE_INPUT" &&
                data?.data?.interaction_type == interactionType
            ) {
                return {
                    image: `data:${data?.data?.content_type};base64,${data?.data?.data}`,
                    filename: data?.data?.filename,
                };
            }
            return null;
        }
        return (interactionType == 'DATE_INPUT' || interactionType == 'DATETIME_INPUT') ? null : '';
    }, [components, step])



    const renderTitle = useCallback(() => {
        // const currentComponentItem = traceData.find(state => state?.data?.message?.block_type == item.data?.interface_block_type && state?.data?.message?.block_id == item?.data?.interface_block_id);
        const currentStepData = progressState.find(
            (state) => state.block_id === components[step]?.data?.interface_block_id
        );
        return currentStepData;
    }, [traceData, step, progressState, components]);

    const handleFileInput = useCallback((file, content) => {
        // Content type validation
        if (
            components?.[step]?.data?.message?.content_type &&
            file.type !== components?.[step]?.data?.message?.content_type
        ) {
            dispatch(setInputError(`Please upload ${components?.[step]?.data?.message?.content_type} format`));
            return;
        }

        // Prepare form data for API
        const formData = new FormData();
        formData.append("interaction_type", "CAMERA_IMAGE_INPUT");
        formData.append("data", file);
        formData.append("filename", file.name);
        formData.append("content_type", file.type);
        formData.append('interface_block_id', components?.[step]?.data?.interface_block_id);

        // Update components array
        const tempArray = components?.map((item) => ({
            ...item,
            value: item.value || "",
            file: item.file || "",
        }));
        tempArray[step].file = file;
        tempArray[step].value = content;

        // Dispatch actions
        dispatch(setFieldValue(tempArray));
        dispatch(setAppLoader(true));

        // Call API
        callNextEventAPI(formData)
            .then((res) => {
                dispatch(setHintData(res.data));
            })
            .catch((err) => dispatch(setInputError(err.response?.data?.message)));
    }, [components, step, dispatch]);

    const handleCapture = (e) => {
        e.preventDefault();

        const video = videoRef.current;
        const canvas = canvasRef.current;
        const contentType = components?.[step]?.data?.message?.content_type || "image/jpeg";
        console.log("Alert", canvas, video)
        if (video && canvas) {
            const context = canvas.getContext("2d");
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

            canvas.toBlob((blob) => {
                const file = new File([blob], `captured_image.${contentType?.split('/')[1]}`, {type: contentType});
                const reader = new FileReader();
                reader.onloadend = () => {
                    handleFileInput(file, reader.result);
                };
                reader.readAsDataURL(blob);
            }, contentType);
        }
    }

    function renderField() {
        let componentType = "";
        // eslint-disable-next-line no-prototype-builtins
        if (components?.[step]?.data?.message?.hasOwnProperty("interaction_type")) {
            componentType = components?.[step]?.data?.message?.interaction_type;
        }
        else if(components?.[step]?.data?.message?.hasOwnProperty('element_type')){
            componentType = components?.[step]?.data?.message?.element_type;
        }
        else {
            componentType = components?.[step]?.data?.message?.message_type;
        }

        switch (componentType) {
            case "LIST_MESSAGE":
                return (
                    <ListElement/>
                )
            case "REDIRECT_MESSAGE":
                return  (
                    <RedirectScreen/>
                )
            case "WAIT_MESSAGE":
                return (
                    <WaitingScreen/>
                )
            case "ELEMENT":
                return (
                    <ElementComp
                        handleNext={handleNext}
                        disabledField={disabledField}
                        socket={socket}
                    />
                );
            case "SELFIE_INPUT":
                return (
                    <SelfieComponent disabledField={disabledField} canvasRef={canvasRef} videoRef={videoRef}
                                     handleCapture={handleCapture}/>

                )
            case "FORM_INPUT":
                return <DynamicForm socket={socket} handleNext={handleNext}
                                    components={components[step]?.data?.message?.components}
                                    interfaceBlockId={components[step]?.data?.interface_block_id}/>;
            case "START_BUTTON":
                // eslint-disable-next-line no-case-declarations
                const isFlashMessage = components?.some(
                    (item) =>
                        item?.data?.message?.message_type == "ELEMENT" &&
                        item?.data?.interface_block_type == "flash_message_widget_block"
                );
                if (!isFlashMessage) {
                    return (
                        <>
                            <div>
                                {components?.[step]?.data?.message?.label ? (
                                    <h1
                                        className={
                                            "text-[32px] text-left sm:text-[24px] font-semibold leading-[48px]"
                                        }
                                    >
                                        {components?.[step]?.data?.message?.label}
                                    </h1>
                                ) : (
                                    <>
                                        <h1
                                            className={
                                                "text-[32px] text-left sm:text-[24px] font-semibold leading-[48px]"
                                            }
                                        >
                                            Let's verify your KYC
                                        </h1>
                                        <p>
                                            Please submit your details to complete your onboarding
                                            journey.
                                        </p>
                                    </>
                                )}
                            </div>
                        </>
                    );
                }
                return null;
            case "DATE_INPUT":
                return (
                    <>
                        <Label>{components?.[step]?.data?.message?.label}</Label>
                        <div className={"flex flex-col items-start gap-4"}>
                            <DateTimePicker
                                granularity="day"
                                value={renderValue(
                                    "DATE_INPUT",
                                    components?.[step]?.value,
                                    components?.[step]?.data?.interface_block_id
                                )}
                                onChange={(value) => {
                                    const tempArray = components?.map((item) => ({
                                        ...item,
                                        value: item.value || "",
                                        file: item.file || "",
                                    }));
                                    tempArray[step]["value"] = dayjs(value).format("YYYY-MM-DD");
                                    dispatch(setFieldValue(tempArray));
                                }}
                            />
                            {errorMessage && (
                                <span className={"text-red-400"}>{errorMessage}</span>
                            )}
                        </div>
                    </>
                );
            case "DATETIME_INPUT":
                return (
                    <form
                        className={"w-full space-y-2"}
                        onSubmit={(e) => e.preventDefault()}
                    >
                        {components?.[step]?.data?.message?.label && (
                            <Label>{components?.[step]?.data?.message?.label}</Label>
                        )}
                        <div className={"flex flex-col items-start  gap-4"}>
                            <DateTimePicker
                                value={renderValue(
                                    "DATETIME_INPUT",
                                    components?.[step]?.value,
                                    components?.[step]?.data?.interface_block_id
                                )}
                                onChange={(value) => {
                                    const tempArray = components?.map((item) => ({
                                        ...item,
                                        value: item.value || "",
                                        file: item.file || "",
                                    }));
                                    tempArray[step]["value"] = value;
                                    dispatch(setFieldValue(tempArray));
                                }}
                            />
                            {errorMessage && (
                                <span className={"text-red-400"}>{errorMessage}</span>
                            )}
                        </div>
                    </form>
                );
            case "SELECT_INPUT":
                return (
                    <SelectComp
                        renderValue={renderValue}
                        handleNext={handleNext}
                        disabledField={disabledField}
                    />
                );
            case "NUMERIC_INPUT":
                return (
                    <TextInputComp
                        elementType={componentType}
                        renderValue={renderValue}
                        handleNext={handleNext}
                        disabledField={disabledField}
                        handleInput={handleInput}
                    />
                );
            case "TEXT_INPUT":
                return (
                    <TextInputComp
                        elementType={componentType}
                        renderValue={renderValue}
                        handleNext={handleNext}
                        disabledField={disabledField}
                        handleInput={handleInput}
                    />
                );
            case "OTP_INPUT":
                return (
                    <TextOtpInput
                        handleInput={handleInput}
                        renderValue={renderValue}
                        handleNext={handleNext}
                        disabledField={disabledField}
                    />
                );
            case "CAMERA_IMAGE_INPUT":
                const label = components[step]?.data?.message?.label?.toLowerCase()?.includes('selfie') || null
                if (label) {
                    return <SelfieComponent disabledField={disabledField} canvasRef={canvasRef} videoRef={videoRef}
                                            handleCapture={handleCapture}/>
                }
                return <CaptureComponent disabledField={disabledField} canvasRef={canvasRef} videoRef={videoRef}
                                         renderValue={renderValue}/>;
            case "FILE_INPUT":
                return (
                    <FileInputComp
                        renderValue={renderValue}
                        disabledField={disabledField}
                    />
                );

            case "SIGNATURE_INPUT":
                return <SignatureComponent
                    handleInput={handleInput}
                    disabledField={disabledField}
                    handleSubmit={handleNext}
                    renderValue={renderValue}
                />
            //Hide keys
            case "TERMINATE":
                // eslint-disable-next-line no-case-declarations
                const isDeadCase = traceData.some(
                    (item) => item?.data?.message?.block_current_status == "FAILED"
                );
                if (isDeadCase) {
                    return (
                        <div
                            className={
                                "w-full flex flex-col  justify-center items-center gap-2"
                            }
                        >
                            <img
                                src={errorSvg}
                                className="w-full max-w-[300px] sm:max-w-[200px] object-contain"
                                alt="Error illustration"
                            />

                            <h1
                                className={
                                    "text-[24px] sm:text-[20px] font-semibold text-center"
                                }
                            >
                                Oops! Something went wrong!
                            </h1>
                            <span className={"text-[20px] sm:text-[16px] text-center"}>
                We regret to inform you that the verification process was
                unsuccessful.
              </span>
                        </div>
                    );
                }
                return (
                    <FinalSuccessWizard/>
                );
            case "HALT":
                return (
                    <div
                        className={
                            "w-full flex flex-col  justify-center items-center gap-4"
                        }
                    >
                        <img
                            src={errorSvg}
                            className="w-full  max-w-[300px] sm:max-w-[200px]  object-contain"
                            alt="Error illustration"
                        />
                        <h1
                            className={"text-[24px] sm:text-[20px] font-semibold text-center"}
                        >
                            Oops! Something went wrong!
                        </h1>
                        <p className="text-[20px] sm:text-[16px] text-center">
                            Please try again later. If the issue continues to occur, please
                            contact our technical support team for assistance.
                        </p>
                    </div>
                );

            //Hide feature
            case "TRACE":
                // eslint-disable-next-line no-case-declarations
                const isStatusCompleted = traceData?.some(
                    (data) =>
                        data?.data?.interface_block_type ===
                        components?.[step]?.data?.interface_block_type &&
                        data?.data?.message?.block_current_status === "COMPLETED"
                );

                const isStatusHalt = traceData?.some(
                    (data) =>
                        data?.data?.interface_block_type ===
                        components?.[step]?.data?.interface_block_type &&
                        data?.data?.message?.block_current_status === "FAILED"
                );

                // eslint-disable-next-line no-case-declarations
                const renderTitleState = renderTitle();

                if (isStatusCompleted) {
                    return (
                        <FinalSuccessWizard/>
                        // <div
                        //     className={
                        //         "w-full flex flex-col justify-center items-center gap-4"
                        //     }
                        // >
                        //     <img
                        //         src={complete}
                        //         className="w-full max-w-[300px] sm:max-w-[200px]  object-contain"
                        //         alt="Complete illustration"
                        //     />
                        //     {renderTitleState && (
                        //         <div className={"space-y space-y-2"}>
                        //             <h1
                        //                 className={
                        //                     "text-[24px] sm:text-[20px] font-semibold text-center"
                        //                 }
                        //             >
                        //                 {startCase(renderTitleState?.humanized_label)}
                        //             </h1>
                        //             <p className={"text-[20px] sm:text-[16px] text-center"}>
                        //                 {startCase(renderTitleState?.description)}
                        //             </p>
                        //         </div>
                        //     )}
                        // </div>
                    );
                }
                if (isStatusHalt) {
                    const title =
                        components[step]?.data?.message?.safe_error_message || "";
                    return (
                        <div
                            className={
                                "w-full flex flex-col justify-center items-center gap-4"
                            }
                        >
                            <img
                                src={errorSvg}
                                className="w-full object-contain max-w-[300px] sm:max-w-[200px]"
                                alt="Error illustration"
                            />
                            {title ? (
                                <p className="text-[24px] font-semibold sm:text-[16px] text-center ">
                                    {title}
                                </p>
                            ) : (
                                <p
                                    className={
                                        "text-[24px] font-semibold sm:text-[16px] text-center"
                                    }
                                >
                                    We're sorry, but we were unable to verify your information at
                                    this time. Please double-check the information you provided
                                    and try again. If the problem persists, please contact our
                                    support team for assistance. Thank you for your
                                    understanding.
                                </p>
                            )}
                        </div>
                    );
                }
                return null;
            default:
                return null;
        }
    }

    function renderScreen (screenType) {
        switch (screenType){
            case "REVERSE_PENNYDROP":
                return <ReversePennyDrop/>
            case "ESIGN":
                return <EsignRedirect/>
            case "DIGILOCKER":
                return <DigilockerRedirect/>
        }
    }



    const pageTitle = useMemo(() => {
        return components[step]?.data?.message?.ui?.screen?.definition?.attributes?.title || ''
    }, [step, components]);

    const pageSubTitle = useMemo(() => {
        return components[step]?.data?.message?.ui?.screen?.definition?.attributes?.subtitle || ''
    }, [step, components]);

    const description = useMemo(() => {
        return components[step]?.data?.message?.ui?.screen?.definition?.attributes?.description || '';
    }, [step, components]);

    const coverImageUrl = useMemo(() => {
        return components[step]?.data?.message?.ui?.screen?.definition?.attributes?.cover_image_url || '';
    }, [step, components]);

    const iconImageUrl = useMemo(() => {
        return components[step]?.data?.message?.ui?.screen?.definition?.attributes?.icon_url || ''
    }, [step, components]);

    const helpText = useMemo(() => {
        return components[step]?.data?.message?.ui?.screen?.definition?.attributes?.help_text || ''
    }, [step, components])

    useEffect(() => {
        setErrorMessage(inputError);
    }, [inputError])

    const screenType = useMemo(() => {
        return components[step]?.data?.message?.ui?.screen?.definition?.wizard_type;
    }, [components, step]);

    useEffect(() => {

        if (inView) {
            control.start("visible");
        } else {
            control.start("hidden");
        }
    }, [control, inView, step]);

    useEffect(() => {
        if (components?.length) {
            const isLastScreenWaiting = components[step]?.data?.message?.message_type=='ELEMENT' && components[step]?.data?.message?.element_type=='WAIT_MESSAGE';
            const isLastScreenBeforeWaiting = replayComponentsData?.find(item=> item?.data?.interface_block_id == components[step-1]?.data?.interface_block_id && components[step]?.data?.message?.element_type=='WAIT_MESSAGE');
            console.log('Is Last Screen', isLastScreenWaiting, components[step-1] )
            if(isLastScreenWaiting && components[step-1]?.data?.message?.ui?.screen?.definition?.wizard_type=='REVERSE_PENNYDROP'){

                dispatch(setSteps(step-1));
            }

            //Check if previous screen before waiting screen is present in replay component;
            else if(isLastScreenWaiting){
                const isPreviousCompCompleted=  replayComponentsData?.find(item=> item.data?.interface_block_id == components[step-1]?.data?.interface_block_id);
                if(isPreviousCompCompleted){
                    setShowWaiting(true);
                }
            }
            else{
                setShowWaiting(false);
            }
        }
        responseTimerRef.current = null;
        responseSecond.current = 0;
    }, [components, replayComponentsData]);



    const isRedirectMessage =  useMemo(()=> {
        return components[step]?.data?.message?.interaction_type=='REDIRECT_MESSAGE' && !replayComponentsData?.some(item=> components[step]?.data?.message?.interaction_type=='REDIRECT_MESSAGE' && components[step]?.data?.message?.interface_block_id == item?.data?.interface_block_id);
    },[step]);

    if(isRedirectMessage && !showWaiting){
        return (
            <RedirectScreen/>
        )
    }
    else if(showWaiting){
        return <WaitingScreen/>
    }
    else if(screenType){
        return renderScreen(screenType);
    }


    return (
        <>
            <div
                className={'flex flex-col h-full'}>
                <Header/>
                <motion.div
                    key={index}
                    ref={ref}
                    transition={{duration: 0.5, ease: "easeOut"}}
                    initial="offscreen"
                    whileInView="onscreen"
                    animate={control}
                    variants={isPrevious ? backCardVariants : cardVariants}
                    className={"relative px-[16px] my-[24px] flex-1 overflow-y-auto"}
                >
                    <div className="space-y-[24px] ">
                        <div className="row-span-1 space-y-4">
                            <div className="flex items-center space-x-4">

                                {/*<ShieldCheck className="w-12 h-12 text-primary flex-shrink-0" />*/}
                                <div>
                                    <h1 className="text-[24px] font-[600] tracking-tight">
                                        {/*Aadhaar Verification*/}
                                        {pageTitle}
                                    </h1>
                                    <p className="text-[18px]  text-[#00000080] ">
                                        {pageSubTitle}
                                    </p>
                                </div>
                            </div>
                            <p className="text-[14px] text-[#00000080]">
                                {description}
                            </p>

                            {/* Optional Vector */}
                            {coverImageUrl ?
                                <div
                                    className="aspect-[1.586/1] w-full overflow-hidden rounded-xl bg-white p-4 shadow-lg">
                                    <img className={'w-full h-full object-contain'}
                                         src={coverImageUrl}/>
                                </div>
                                : null}
                        </div>
                        <form className="row-span-1 py-4 space-y-2" onSubmit={(e) => {
                            e.preventDefault()
                        }}>
                            {/*<SignatureComponent disabledField={disabledField}/>*/}
                            {renderField()}
                        </form>

                        {iconImageUrl ? (
                            <div
                                className={'background-["#F6F9FF"]'}
                                style={{backgroundColor: "rgba(246, 249, 255, 1)"}}
                            >
                                <div className="flex place-items-center gap-[8px] px-[8px] py-[16px] ">
                                    {iconImageUrl && (
                                        <div className={'w-[24px] h-[24px] bg-white rounded-full'}>
                                            <img src={iconImageUrl} className={'w-full h-full object-contain'}/>
                                        </div>
                                    )}
                                    <p className="leading-[24px] text-[14px]">
                                        {helpText}
                                    </p>
                                </div>
                            </div>
                        ) : null}

                    </div>
                </motion.div>
                {components[step]?.data?.message?.message_type !== 'TERMINATE' && (
                    <div>
                        <FooterComp
                            handleCapture={handleCapture}
                            handleClick={() => {
                            }}
                            isDisabled={disabledField}/>
                    </div>
                )}
            </div>
        </>
    )

}
