import React, {useEffect, useState} from "react";
import {Label} from "/components/ui/label.jsx";
import {RadioGroup, RadioGroupItem} from "/components/ui/radio-group.jsx"
import phonepay from "/public/assets/phonepay.png";
import whatsapp from "/public/assets/whatsapp.png";
import googlepay from "/public/assets/gpay.png";
import bhimpay from "/public/assets/bhim.png";
import paytm from "/public/assets/paytm.png";
import {useSelector} from "react-redux";
import {List} from "lucide-react";
import list from "/public/assets/list.svg";

export default function ReversePennyDrop() {

    const [paymentMethod, setPaymentMethod] = useState("paytm");
    const {
        step,
        components,
        progressState,
        socket,
        formInputFields,
        appLoader,
        startInterfaceBlockID,
        brandingData
    } = useSelector((state) => state.userSlice);

    const [listItem, setListItem] = useState([]);

    const handleFocus = () => {
        setUpiError("");
    };

    const handleSubmit = (e) => {
        if (paymentMethod) {
            const paymentLink = components[step]?.data?.message?.list;
        }
    }

    useEffect(() => {
        if (components?.length && components[step]?.data?.message?.interaction_type === 'LIST_MESSAGES') {
            const reversePennyDropItem = components[step]?.data?.message?.values;
        }
    }, [step, components]);

    function renderIcon(title) {
        switch (title) {
            case 'Google Pay':
                return googlepay;
            case 'PhonePe':
                return phonepay;
            case 'BHIM':
                return bhimpay;
            case 'Whatsapp':
                return whatsapp;
            case 'Paytm':
                return paytm;
            case 'Others':
                return list;
        }
    }

    useEffect(()=>{
        if(components[step]){
            setListItem(components[step]?.data?.message?.values);
        }
    },[step, components]);

    const handleInput = (value) => {
        const tempArray = components?.map(item => ({
            ...item, value: item.value || '', file: item.file || ''
        }));
        tempArray[step]['value'] = value;
        dispatch(setFieldValue(tempArray));
    }


    return (
        <div className="flex flex-col gap-6 mt-6 mx-4">
            <p className={''}>{components[step]?.data?.message?.text}</p>
            {listItem.map(item => (
                <RadioGroup value={paymentMethod} onValueChange={handleInput}
                >
                    <div className="flex items-center justify-between p-4 rounded-lg border">
                        <div className="flex items-center gap-3">
                            <div className={'flex justify-center items-center h-[32px] w-[32px]'}>
                                <img
                                    src={renderIcon(item?.text)}
                                    alt="Paytm"

                                    className={'w-full object-contain'}
                                />
                            </div>

                            <Label htmlFor="paytm" className="text-[16px] font-[400]">{item?.text}</Label>
                        </div>
                        <RadioGroupItem className={'h-[24px] w-[24px]'} value={item?.parameters?.href} id={item?.text}/>
                    </div>
                </RadioGroup>
            ))}
        </div>
    );
}
