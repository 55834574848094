import React, {useCallback, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button} from "../../../../components/ui/button.jsx";
import {Camera, Check, Loader2, RotateCw, SwitchCamera} from "lucide-react";
import {Dialog, DialogContent} from "../../../../components/ui/dialog.jsx";
import {callNextEventAPI} from "../../../services/api/api.js";
import {setAppLoader, setFieldValue, setHintData} from "../../../slices/slices.js";
import {Label} from "/components/ui/label.jsx";

const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

export default function CaptureComponent(props) {
    const {renderValue, canvasRef, videoRef, disabledField, handleCapture} = props;
    const {components, step, appLoader, replayComponentsData} = useSelector(state => state.userSlice);
    const dispatch = useDispatch();

    const [error, setError] = useState("");
    const [image, setImage] = useState('');
    const [facingMode, setFacingMode] = useState(FACING_MODE_USER);
    const [blobFile, setBlobFile] = useState(null);
    const streamRef = useRef(null);
    const [crop, setCrop] = useState('');
    const [completedCrop, setCompletedCrop] = useState();
    const imageRef = useRef(null);
    const [isMobile, setIsMobile] = useState(false);

    const [isCapture, setIsCaptured] = useState(false);
    const [isRetake, setIsRetake] = useState(false);

    const startVideoStream = useCallback(async () => {
        if (!videoRef.current) return;

        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                audio: false,
                video: {
                    facingMode: {ideal: facingMode},
                    video: {
                        width: { ideal: 4096 },
                        height: { ideal: 2160 }
                    }
                }
            });

            streamRef.current = stream;
            if (videoRef.current) {
                videoRef.current.srcObject = stream;
                await videoRef.current.play();
            }
        } catch (err) {
            setError("Camera access error. Please check permissions.");
            console.error(err);
        }
    }, [facingMode]);

    const stopVideoStream = useCallback(() => {
        if (streamRef.current) {
            streamRef.current.getTracks().forEach(track => track.stop());
            streamRef.current = null;
        }
        if (videoRef.current) {
            videoRef.current.srcObject = null;
        }
    }, []);


    const handleCaptureImage = (e) => {
        e.preventDefault();
        const video = videoRef.current;
        // const canvas = canvasRef.current;
        const contentType = components?.[step]?.data?.message?.content_type || "image/jpeg";

        if (video) {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext("2d");
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

            canvas.toBlob((blob) => {
                const file = new File([blob], `captured_image.${contentType?.split('/')[1]}`, {type: contentType});
                const reader = new FileReader();
                reader.onloadend = () => {
                    setIsCaptured(true);
                    setImage(reader.result);
                    setBlobFile(file);
                };
                reader.readAsDataURL(blob);
            }, contentType);
        }
    }

    useEffect(() => {
        const initCamera = async () => {
            if (videoRef.current && !isCapture) {
               videoRef.current.play(); // Attempt to play video after it's available
            }
            if (!isCapture) {
                await startVideoStream();
            }
        };
        setTimeout(initCamera, 100); // Small delay to ensure element is ready
        return () => stopVideoStream();
    }, [ startVideoStream, stopVideoStream, isCapture]);

    const retake = () => {
        setIsCaptured(false);
        setImage('');
    }

    const getCroppedImg = useCallback(() => {
        if (completedCrop && imageRef.current) {
            const canvas = document.createElement('canvas')
            const scaleX = imageRef.current.naturalWidth / imageRef.current.width
            const scaleY = imageRef.current.naturalHeight / imageRef.current.height
            canvas.width = completedCrop.width
            canvas.height = completedCrop.height
            const ctx = canvas.getContext('2d')

            if (ctx) {
                ctx.drawImage(
                    imageRef.current,
                    completedCrop.x * scaleX,
                    completedCrop.y * scaleY,
                    completedCrop.width * scaleX,
                    completedCrop.height * scaleY,
                    0,
                    0,
                    completedCrop.width,
                    completedCrop.height
                )
            }

            return canvas.toDataURL('image/jpeg')
        }
        return null
    }, [completedCrop]);

    const confirmCrop = () => {
        const croppedImageData = getCroppedImg()
        if (croppedImageData) {
            setImage(croppedImageData)
            setCrop(undefined)
            setCompletedCrop(undefined)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (crop) {
            confirmCrop()
        } else {
            const formData = new FormData();
            formData.append("interaction_type", "CAMERA_IMAGE_INPUT");
            formData.append("data", blobFile);
            formData.append("filename", blobFile.name);
            formData.append("content_type", blobFile.type);
            formData.append('interface_block_id', components?.[step]?.data?.interface_block_id);

            // Update components array
            const tempArray = components?.map((item) => ({
                ...item,
                value: item.value || "",
                file: item.file || "",
            }));
            tempArray[step].file = blobFile;
            tempArray[step].value = image;

            // Dispatch actions
            dispatch(setFieldValue(tempArray));
            dispatch(setAppLoader(true));

            // Call API
            callNextEventAPI(formData)
                .then((res) => {
                    dispatch(setHintData(res.data));
                })
        }

        // .catch((err) => dispatch(setInputerr.response?.data?.message));
    }

    useEffect(() => {
        if (replayComponentsData?.length) {
            const imageComponent = replayComponentsData.filter(replay => components[step]?.data?.interface_block_id == replay.data?.interface_block_id);
            console.log('Image Component', imageComponent, replayComponentsData, components)
            if (imageComponent?.length) {
                setIsCaptured(true);
                setImage(`data:image/png;base64,${imageComponent[0]?.data?.data}`);
            }
        }
        if (components[step]?.value) {
            setImage(components[step]?.value);
            setIsCaptured(true);
        }
        else{
            setIsCaptured(false);
        }
    }, [ step, components]);

    // Switch camera facing mode
    const switchCamera = () => {
        setFacingMode((prevMode) =>
            prevMode === FACING_MODE_USER
                ? FACING_MODE_ENVIRONMENT
                : FACING_MODE_USER
        );
    };

    useEffect(() => {
        const checkMobile = () => {
            const mobileCheck = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
            setIsMobile(mobileCheck);
            if (mobileCheck) {
                setFacingMode(FACING_MODE_USER);
            }
        };

        checkMobile();
        window.addEventListener('resize', checkMobile);

        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    console.log("Image", components[step] ,image, disabledField);

    return (
        <>
            {image && disabledField ?
                <div className="relative gap-y-2 flex-1 flex flex-col justify-center items-center">
                    <div>
                        <img
                            ref={imageRef}
                            src={image}
                            alt="Captured Document"
                            className="w-full h-full object-contain"
                        />
                        {appLoader && (
                            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                                <div className="w-16 h-16 border-4 border-white border-t-transparent rounded-full animate-spin"></div>
                            </div>
                        )}
                    </div>
                </div> :

                <div className={`mx-auto sm:w-full h-full overflow-y-auto p-0 flex flex-col`}>
                        {!isCapture ? (
                            // Camera Capture Screen
                            <div className="relative flex-1 flex flex-col">
                                <Label>{components[step]?.data?.message?.label}</Label>
                                {isMobile && (
                                    <Button size={'icon'} className="absolute top-2 right-2 rounded-full bg-slate-200 text-black z-10" onClick={()=>{
                                        switchCamera();
                                    }}>
                                        <SwitchCamera/>
                                    </Button>
                                )}
                                <video
                                    ref={videoRef}
                                    autoPlay
                                    playsInline
                                    className="w-full h-full object-cover"
                                />


                                <div className=" hidden flex-1 relative">

                                    {/*/!* PAN card overlay guide *!/*/}
                                    {/*<div*/}
                                    {/*    className="absolute inset-0 bottom-24 flex flex-col items-center justify-center p-4">*/}
                                    {/*    <div*/}
                                    {/*        className="border-2 border-white/50 rounded-lg w-[90%] aspect-[1.6/1] mb-4">*/}
                                    {/*        <div className="absolute left-0 right-0 -bottom-16 text-center">*/}
                                    {/*            <h2 className="text-white text-lg font-semibold">*/}

                                    {/*                {components[step]?.data?.message?.ui_config?.title}</h2>*/}
                                    {/*            <p className="text-white/70 text-sm">*/}

                                    {/*                {components[step]?.data?.message?.ui_config?.description}*/}
                                    {/*            </p>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                                {/* Camera controls */}
                                <div className={'hidden my-8'}>
                                    <div className="flex justify-center items-center gap-12">
                                        <Button
                                            size="icon"
                                            className="rounded-full bg-white text-black hover:bg-white/90"
                                            onClick={(e) => {
                                                handleCapture(e)
                                            }}
                                        >
                                            <Camera size={16}/>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            // Preview Screen
                            <div className="relative gap-y-2 flex-1 flex flex-col">
                                <div>
                                    <img
                                        ref={imageRef}
                                        src={image}
                                        className="w-full h-full object-contain mx-auto"
                                    />
                                    {appLoader && (
                                        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                                            <div className="w-16 h-16 border-4 border-white border-t-transparent rounded-full animate-spin"></div>
                                        </div>
                                    )}
                                </div>
                                {/* Preview controls */}
                                <div className="hidden w-full bg-gray-900 p-2">
                                    <div className="flex justify-center gap-4">
                                        <Button
                                            disabled={appLoader}
                                            variant="outline"
                                            className="flex gap-x-2 flex-1"
                                            onClick={(e) => {
                                                retake(e)
                                            }}
                                        >
                                            Retake
                                        </Button>
                                        <Button
                                            disabled={appLoader}
                                            className="flex gap-x-2 flex-1"
                                            onClick={handleSubmit}
                                        >
                                            {appLoader ? <>
                                                    <Loader2 className="mr-2 h-4 w-4 animate-spin"/>
                                                    Verifying...
                                                </> :
                                                <>
                                                    <Check size={16}/>
                                                    Confirm
                                                </>
                                            }
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        )}
                </div>}

            <canvas ref={canvasRef} className={'hidden'}/>
        </>
    );
}
