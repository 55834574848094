import React, {useMemo, useState, useEffect} from "react";
import { useSelector } from "react-redux";
import Header from "/src/views/components/Header/Header.jsx"
import RedirectAnimated from "/src/views/components/RedirectComponent/RedirectAnimation.jsx";
import {Button} from "/components/ui/button.jsx";

export default function EsignRedirect(){
    const {components, step} = useSelector((state)=>state.userSlice);
    const componentConfig = useMemo(()=>{
        let redirectConfig =  components[step]?.data?.message?.ui?.screen?.definition?.attributes;
        return redirectConfig;
    },[step]);

    const [isLoading, setIsLoading] = useState(true);

    setTimeout(() => {
        console.log("Success");
        setIsLoading(false);
    }, 3000); // Simulating network delay

    const handleSubmit = () => {
        if(components[step]?.data?.message?.redirect_behaviour =='SEAMLESS'){
            window.open(components[step]?.data?.message?.href,'_self');
        }
        else{
            window.open(components[step]?.data?.message?.href,'_target');
        }
    };

    useEffect(()=>{
        setTimeout(()=>{
            handleSubmit()
        },3000)

    },[])

    let isDisabled = false;

    return (
        <>
                    <div className="flex justify-center mt-[88px] mx-[16px]">
                        <RedirectAnimated />
                    </div>

                    {componentConfig?.icon_url ? (
                        <div
                            className={'background-["#F6F9FF"] my-5'}
                            style={{backgroundColor: "rgba(246, 249, 255, 1)"}}
                        >
                            <div className="flex place-items-center gap-[8px] px-[8px] py-[16px] ">
                                {componentConfig?.icon_url  && (
                                    <div className={'w-[24px] h-[24px] bg-white rounded-full'}>
                                        <img src={componentConfig?.icon_url } className={'w-full h-full object-contain'}/>
                                    </div>
                                )}
                                <p className="leading-[24px] text-[14px]">
                                    {componentConfig?.help_text}
                                </p>
                            </div>

                        </div>
                    ) : null}
                    <p>{components[step]?.data?.message?.text}</p>
        </>
    );
}

