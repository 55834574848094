import React, {useState, useMemo, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {InputOTP, InputOTPGroup, InputOTPSeparator, InputOTPSlot} from "../../../../components/ui/input-otp.jsx";
import {Button} from "../../../../components/ui/button.jsx";
import otp from "/assets/otp_creative.png";
import {setFieldValue, setAppLoader,} from "../../../slices/slices.js";
import {Loader2} from "lucide-react";
import {Label} from "/components/ui/label";

export default function TextOtpInput(props) {
    const {renderValue, handleNext, disabledField} = props;
    const {components, step, brandingData, appLoader, inputError} = useSelector(state => state.userSlice);
    const [errorMessage, setErrorMessage] = useState('');
    const dispatch = useDispatch();
    const messageLength = components?.[step]?.data?.message?.length || 4;

    const placeholder =  useMemo(()=>{
        return components[step]?.data?.message?.ui?.screen?.definition?.attributes?.placeholder || ''
    },[step, components]);

    const helpText = useMemo(()=>{
        return components[step]?.data?.message?.ui?.screen?.definition?.attributes?.help_text || inputError;
    },[step, inputError])

    const label = useMemo(()=>{
        if(components?.[step]?.data?.message?.label){
            return components?.[step]?.data?.message?.label
        }

        return `Enter ${messageLength} digit verification code send to your number`
    },[step])

    useEffect (()=>{
        setErrorMessage(inputError)
    },[inputError]);

    return (<>
        <Label>{label}</Label>
        <InputOTP
            autoFocus={true}
            disabled={appLoader}
            maxLength={messageLength || 4}
            value={renderValue("OTP_INPUT", components?.[step]?.value, components?.[step]?.data?.interface_block_id)}
            onChange={(value) => {
                const tempArray = components?.map(item => ({
                    ...item, value: item.value || '', file: item.file || ''
                }));
                tempArray[step]['value'] = value;
                dispatch(setFieldValue(tempArray));
            }}
        >
            {Array.from({length: messageLength}).map((_, i) => {

                // Calculate the group size for dividing the OTP input fields
                const groupSize = messageLength === 6 ? 3 : Math.ceil(messageLength / 2);

                return (
                    <React.Fragment key={i}>
                        <InputOTPGroup>
                            <InputOTPSlot index={i}/>
                        </InputOTPGroup>
                    </React.Fragment>
                );
            })}
        </InputOTP>
        {errorMessage && <span className={'my-2 text-sm text-red-400'}>{errorMessage}</span>}
    </>);
}
