import React, {useMemo} from "react";
import {Button} from "/components/ui/button.jsx";
import {useDispatch, useSelector} from "react-redux";

import {setAppLoader, setInputError, setSteps, setHintData} from "../../../../slices/slices.js";
import {Loader2} from "lucide-react";
import {callNextEventAPI} from "../../../../services/api/api.js"

export default function FooterComp({handleClick, isDisabled = false, handleCapture}) {
    const dispatch = useDispatch();
    const {
        step,
        components,
        progressState,
        socket,
        formInputFields,
        appLoader,
        startInterfaceBlockID,
        brandingData
    } = useSelector((state) => state.userSlice);

    const maxLength = useMemo(() => {
        if (components?.[step]?.data?.message?.interaction_type == 'NUMERIC_INPUT') {
            return components?.[step]?.data?.message?.lt || undefined;
        } else {
            return components?.[step]?.data?.message?.max_length || undefined;
        }
    }, [step]);

    const minLength = useMemo(() => {
        if (components?.[step]?.data?.message?.interaction_type == 'NUMERIC_INPUT') {
            return components?.[step]?.data?.message?.gt || undefined;
        } else {
            return components?.[step]?.data?.message?.min_length || undefined;
        }
    }, [step]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const interactionType = components[step]?.data?.message?.interaction_type || components?.[step]?.data?.message?.message_type;
        const interfaceBlockId = components[step]?.data?.interface_block_id;
        let file = null;
        let data =null;
        dispatch(setInputError(''));
        console.log("Components", components[step])
        if(interactionType=='FILE_INPUT'){
            if(components[step]?.file){
                file =components[step]?.file;
            }
            if(components[step]?.value){
                data= value
            }
        }
        if (step < components?.length-1) {
            dispatch(setSteps(step + 1));
        }
        else {
            switch (interactionType) {
                case "ELEMENT":

                    let startBlockId = progressState?.find(item => item?.block_type == "on_start_widget_block");
                    let flashMessageBlock = components[step]?.data?.interface_block_type === 'flash_message_widget_block';
                    if (flashMessageBlock) {
                        dispatch(setAppLoader(true));
                        console.log("Start BlockId", startInterfaceBlockID, components, progressState);
                        socket.emit('interface_interaction', {
                            interface_block_id: startInterfaceBlockID,
                            interaction_type: 'START_BUTTON'
                        });
                    } else {
                        dispatch(setSteps(step + 1));
                    }

                    return;

                case "FORM_INPUT":

                    // eslint-disable-next-line no-case-declarations
                    let isData = formInputFields.every(
                        (item) => item?.value !== ''
                    );

                    console.log(isData, formInputFields, "Is Data");

                    // eslint-disable-next-line no-case-declarations
                    let payloadComponent = []

                    if (isData) {
                        formInputFields.forEach((item) => {
                            if (item?.interaction_type == "FILE_INPUT" && item?.value && item?.file) {
                                payloadComponent.push({
                                    interaction_type: item?.interaction_type,
                                    data: item?.value || item?.file,
                                });
                            } else if (item?.interaction_type == "TEXT_INPUT") {
                                payloadComponent.push({
                                    interaction_type: item?.interaction_type,
                                    text: item?.value,
                                });
                            } else if (item?.interaction_type == "NUMERIC_INPUT") {
                                payloadComponent.push({
                                    interaction_type: item?.interaction_type,
                                    value: item?.value,
                                });
                            } else if (item?.interaction_type == "OTP_INPUT") {
                                payloadComponent.push({
                                    interaction_type: item?.interaction_type,
                                    value: item?.value,
                                });
                            } else if (item?.interaction_type == "SELECT_INPUT") {
                                const filterItem = item?.section?.[0]?.items?.filter((data) =>
                                    data?.name == item?.value ? data : null
                                );
                                console.log("filterItem", filterItem, item);
                                payloadComponent.push({
                                    interaction_type: item?.interaction_type,
                                    title: item?.value,
                                    reference_id: filterItem?.[0]?.reference_id,
                                });
                            } else if (item?.interaction_type == "DATE_INPUT") {
                                payloadComponent.push({
                                    interaction_type: item?.interaction_type,
                                    value: item?.value,
                                });
                            } else if (item?.interaction_type == "DATETIME_INPUT") {
                                payloadComponent.push({
                                    interaction_type: item?.interaction_type,
                                    value: item?.value,
                                });
                            }
                            return undefined;
                        });

                        dispatch(setAppLoader(true));
                        socket.emit('interface_interaction', {
                            interaction_type: interactionType,
                            interface_block_id: interfaceBlockId,
                            components: payloadComponent
                        });
                    } else {
                        dispatch(setInputError("Please fill all the fields"));
                    }

                    return null;
                case "START_BUTTON":
                    // eslint-disable-next-line no-case-declarations
                    return null;

                case "DATE_INPUT":
                    if (components[step]?.value) {
                        dispatch(setInputError(""));
                        dispatch(setAppLoader(true));
                        socket.emit('interface_interaction', {
                            interaction_type: components[step]?.data?.message?.interaction_type,
                            value: components?.[step]?.value,
                            interface_block_id: components[step]?.data?.interface_block_id,
                        });
                        return null
                    }
                    return null;

                case "DATETIME_INPUT":

                    if (components[step]?.value) {
                        dispatch(setInputError(""));
                        dispatch(setAppLoader(true));
                        socket.emit('interface_interaction', {
                            interaction_type: components[step]?.data?.message?.interaction_type,
                            value: components?.[step]?.value,
                            interface_block_id: components[step]?.data?.interface_block_id,
                        });
                        return null
                    }
                    dispatch(setInputError('Please fill the empty field'));
                    return null;

                case "SELECT_INPUT":

                    if (components[step]?.value) {
                        const filterArray = components[step]?.data?.message?.section?.[0]?.items?.filter((item) => item?.name == components[step]?.value ? item : null);
                        dispatch(setAppLoader(true));
                        socket.emit('interface_interaction', {
                            interface_block_id: interfaceBlockId,
                            interaction_type: "SELECT_INPUT",
                            title: components[step]?.value,
                            reference_id: filterArray[0]?.reference_id,
                        });
                        return null;
                    } else {
                        dispatch(setInputError("Please select a value"));
                        return null;
                    }

                case "TEXT_INPUT":
                case "NUMERIC_INPUT":
                    const value = components?.[step]?.value;
                    if (!value) {
                        dispatch(setInputError(`Input field should not be empty`));
                        return;
                    } else if (components?.[step]?.data?.message?.validation_regex && !components?.[step]?.data?.message?.validation_regex?.test(value)) {
                        dispatch(setInputError(`Regex error: Value does not match the required pattern. ${components?.[step]?.data?.message?.validation_regex}`));
                        return;
                    } else if (minLength && value?.length !== minLength) {
                        dispatch(setInputError(`Input value must contain at least ${minLength} characters `));
                        return;
                    } else if (maxLength && value?.length !== maxLength) {
                        dispatch(setInputError(`Input value must be less than ${maxLength} characters `));
                        return;
                    }

                    dispatch(setAppLoader(true));
                    socket.emit('interface_interaction', {
                        interaction_type: interactionType,
                        [interactionType == 'NUMERIC_INPUT' ? 'value' : 'text']: value,
                        interface_block_id: interfaceBlockId,

                    });
                    return null;

                case "OTP_INPUT":
                    console.log("Components", components[step], socket);
                    dispatch(setAppLoader(true));
                    socket.emit('interface_interaction', {
                        interaction_type: 'OTP_INPUT',
                        value: components?.[step]?.value,
                        interface_block_id: interfaceBlockId,
                    });
                    return null;


                case "CAMERA_IMAGE_INPUT":
                case "SELFIE_INPUT":
                    handleCapture(e);
                    return null;

                case "FILE_INPUT":

                    if(file){
                        const formData = new FormData();
                        formData.append('interaction_type', 'FILE_INPUT');
                        formData.append('interface_block_id', components?.[step]?.data?.interface_block_id);
                        formData.append('data', file);
                        formData.append('filename', file.name);
                        formData.append('content_type', file.type);
                        dispatch(setAppLoader(true));
                        callNextEventAPI(formData).then(res => {
                            dispatch(setHintData(res.data));
                        }).catch(err => console.log("Error", err));
                        return null;
                    }
                    console.log("File", file);
                    dispatch(setInputError('Please upload the file'));
                    return null;

                case "SIGNATURE_INPUT":
                    return null;

                default:
                    return null;
            }
        }

    }


    const renderButtonLabel = useMemo(() => {
        let buttonLabel = components[step]?.data?.message?.ui?.screen?.definition?.attributes?.primary_button_text
        if (components?.[step]?.data?.message?.interaction_type === "CAMERA_IMAGE_INPUT" ) {
            return step < components?.length - 1 ? 'Next' : 'Capture'
        }else if (components?.[step]?.data?.message?.interaction_type === "SELFIE_INPUT" ) {
            return step < components?.length - 1 ? 'Next' : 'Capture'
        } else if (step < components?.length - 1) {
            return 'Next';
        }
        return buttonLabel || 'Proceed';
    }, [components?.[step]]);

    const disabledButton = useMemo(()=>{
        if(step<components?.length-1){
            return false
        }
        if(isDisabled && components?.[step]?.data?.interface_block_type=='flash_message_widget_block'){
            console.log('enter' )
            return false;
        }
        else if (isDisabled && components?.[step]?.data?.message?.interaction_type =='FORM_INPUT'){
            console.log("Enter form")
            let isData = formInputFields.every(
                (item) => item?.value !== ''
            );
            if(isData){
                return false;
            }
            return true;
        }
        else if(isDisabled){
            return true;
        }
        return false
    },[components, step]);

    const footNote =  useMemo(()=>{
        return  components[step]?.data?.message?.ui?.screen?.definition?.attributes?.footnotes|| 'Your information is secure and will only be used for verification purposes'
    },[step, components])

    return (
        <footer className="">
            <div className={'px-4 space-y-[16px] mb-[24px]'}>
                <p
                    className="hidden text-[14px] font-normal"
                    style={{color: "rgba(0, 0, 0, 0.6)"}}
                >
                    {footNote}

                </p>
                <Button
                    variant={disabledButton ? 'disabled' : 'default'}
                    onClick={handleSubmit}
                    style={{
                        background: brandingData?.accent_colour
                            ? "#" + brandingData?.accent_colour
                            : "#000",
                    }}
                    disabled={appLoader || disabledButton}>
                    {appLoader ? (
                            <>
                                <Loader2 className="mr-2 h-4 w-4 animate-spin"/>
                                Verifying...
                            </>
                        ) :
                        renderButtonLabel
                    }
                </Button>
            </div>
            <div className={'px-2 mt-2 py-2 border-t'}>
                <p className={'text-center text-[12px]'}>Powered by Superflow</p>
            </div>
        </footer>
    );
}
