import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { IoMdArrowBack } from "react-icons/io";
import { Button } from "/components/ui/button.jsx";
import {setSteps} from "/src/slices/slices.js";

export default function Header() {
    const { brandIcon, step , brandingData} = useSelector((state) => state.userSlice);
    const dispatch  = useDispatch();

    return (
        <header className="w-full h-[64px] p-[16px] z-50 border-b border-gray-200">
            <div className="flex w-full  gap-x-[16px] items-center ">
                {/* Back Button */}
                <Button
                    size={'icon'}
                    variant="icon" // You can change the variant as needed
                    className="p-2 rounded-md bg-gray-100 flex justify-center items-center"
                    onClick={() => {
                        if(step>0){
                            dispatch(setSteps(step-1))
                        }
                    }} // Example: navigate back

                >
                    <IoMdArrowBack size={20} className="text-black " />
                </Button>

                {/* Brand Icon */}
                <div className={
                    'w-[132px] h-[32px]'
                }>
                    <img
                        className="w-full h-full object-contain"
                        src={

                            brandIcon ||
                            "https://surepass.io/wp-content/uploads/2019/09/2-layers@2x.png"
                        }
                        alt="Brand Icon"
                    />
                </div>


                <div className={'w-full flex justify-end'}>
           <span
               className="font-[500] sm:text-sm text-[20px] ">{brandingData?.brand_name}</span>
                </div>

            </div>
        </header>
    );
}
