import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callNextEventAPI } from "../../../services/api/api.js";
import { Button } from "../../../../components/ui/button.jsx";
import { setAppLoader, setFieldValue, setHintData } from "../../../slices/slices.js";
import { Camera, Upload, RefreshCcw, X, Check } from "lucide-react";
import { Card, CardContent } from "../../../../components/ui/card.jsx";
import { useFilePicker } from "use-file-picker";
import "./index.css";
import {Label} from "../../../../components/ui/label.jsx";
import sampleImage from "/public/assets/faceSample1.png";
import sampleImage2 from "/public/assets/faceSample2.png";
import sampleImage3 from "/public/assets/faceSample3.png";
import {CheckIcon} from "@radix-ui/react-icons";
import {Dialog, DialogContent} from "../../../../components/ui/dialog.jsx";
import documentDetect from "../../../../public/assets/document-detect.jpeg";

// Constants for camera facing modes
const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

function GuidelineItem({ image, status, text }) {
    return (
        <div className="flex flex-col items-center gap-1">
            <div className="relative rounded-lg border-2 border-dashed p-1 w-16 h-16 shrink-0">
                <img
                    src={image}
                    alt={text}
                    fill
                    className="rounded-lg object-cover"
                />
                <div className="absolute -bottom-1 -right-1 rounded-full bg-white">
                    {status === 'success' ? (
                        <div className="rounded-full bg-green-500 p-1">
                            <Check className="h-3 w-3 text-white" />
                        </div>
                    ) : (
                        <div className="rounded-full bg-red-500 p-1">
                            <X className="h-3 w-3 text-white" />
                        </div>
                    )}
                </div>
            </div>
            <p className="text-xs text-center text-muted-foreground max-w-[80px]">{text}</p>
        </div>
    )
}


export default function SelfiComponent(props) {
    const {canvasRef, videoRef, handleCapture} = props;

    const {
        components,
        step,
        brandingData,
        appLoader,
        traceData,
        progressState,
replayComponentsData

    } = useSelector(state => state.userSlice);

    const dispatch = useDispatch();

    const [openCamera, setOpenCamera] = useState(false);

    // Enhanced state management
    const [error, setError] = useState("");
    const [facingMode, setFacingMode] = useState(FACING_MODE_USER);
    const [isMobile, setIsMobile] = useState(false);
    const [capturedImage, setCapturedImage] = useState(null);
    const [cameraInitialized, setCameraInitialized] = useState(false);
    const [cameraAccessDenied, setCameraAccessDenied] = useState(false);
    const [isCaptured, setIsCaptured] = useState(false);


    // Memoized important notes for selfie component
    const importantNotes = useMemo(
        () => [
            {
                description:'Neutral expressions',
                image:sampleImage,

            },
            {
                description:  "Full image should be in frame",
                image: sampleImage
            },
            {
                description: "Do not upload Blur images",
                image: sampleImage
            },
            {
                description:  "No light reflection in image",
                image:sampleImage
            },
        ],
        []
    );

    // Check if this is a selfie component
    const isSelfieComponent = useMemo(() => {
        return components?.[step]?.data?.message?.label?.includes("selfie");
    }, [components, step]);

    // Switch camera facing mode
    const switchCamera = () => {
        setFacingMode((prevMode) =>
            prevMode === FACING_MODE_USER
                ? FACING_MODE_ENVIRONMENT
                : FACING_MODE_USER
        );
    };

    // Start video stream with robust error handling
    const startVideoStream = useCallback(() => {
        const video = videoRef.current;
        if (video) {
            // Comprehensive camera constraints
            const constraints = {
                audio: false,
                video: {
                    facingMode: { ideal: facingMode },
                    width: { ideal: 1280, max: 1920 },
                    height: { ideal: 720, max: 1080 },
                    frameRate: { ideal: 30, max: 60 }
                }
            };

            // Stop existing tracks
            if (video.srcObject) {
                video.srcObject.getTracks().forEach(track => track.stop());
            }

            // Primary camera access attempt
            navigator.mediaDevices
                .getUserMedia(constraints)
                .then((stream) => {
                    video.srcObject = stream;
                    video.onloadedmetadata = () => {
                        video.play();
                        setCameraInitialized(true);
                        setCameraAccessDenied(false);
                        setError("");
                    };
                })
                .catch((err) => {
                    console.error(`Primary camera access error: ${err.name}: ${err.message}`);

                    // Fallback constraints
                    const fallbackConstraints = {
                        audio: false,
                        video: {
                            width: { ideal: 640 },
                            height: { ideal: 480 }
                        }
                    };

                    // Secondary fallback attempt
                    navigator.mediaDevices
                        .getUserMedia(fallbackConstraints)
                        .then((stream) => {
                            video.srcObject = stream;
                            video.onloadedmetadata = () => {
                                video.play();
                                setCameraInitialized(true);
                                setCameraAccessDenied(false);
                                setError("");
                            };
                        })
                        .catch((fallbackErr) => {
                            console.error(`Fallback camera error: ${fallbackErr.name}: ${fallbackErr.message}`);
                            // setError("Unable to access camera. Please check permissions.");
                            setCameraInitialized(false);
                            setCameraAccessDenied(true);
                        });
                });
        }
    }, [facingMode]);

    // Mobile detection effect
    useEffect(() => {
        const checkMobile = () => {
            const mobileCheck = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
            setIsMobile(mobileCheck);
            if (mobileCheck) {
                setFacingMode(FACING_MODE_USER);
            }
        };

        checkMobile();
        window.addEventListener('resize', checkMobile);

        return () => window.removeEventListener('resize', checkMobile);
    }, []);



    // Camera initialization effect
    useEffect(() => {
        startVideoStream();
    }, [facingMode, isMobile]);

    // Reset captured image on step change
    useEffect(() => {
        setCapturedImage(null);
    }, [step]);

    useEffect(()=>{
        if (replayComponentsData?.length) {
            const imageComponent = replayComponentsData.filter(replay => components[step]?.data?.interface_block_id == replay.data?.interface_block_id);
            console.log('Image Component', imageComponent, replayComponentsData, components)
            if (imageComponent?.length) {
                setIsCaptured(true);
                setCapturedImage(`data:image/png;base64,${imageComponent[0]?.data?.data}`);
            }
        }
        if (components[step]?.value) {
            setCapturedImage(components[step]?.value);
            setIsCaptured(true);
        }
        else{
            setIsCaptured(false);
        }
    },[step, components])

    return (
        <>
            <div className="aspect-square  max-w-sm mx-auto relative">
                <div className="absolute inset-0 rounded-full border-4 border-dashed border-muted-foreground overflow-hidden">
                    {capturedImage ? (
                        <img
                            src={capturedImage}
                            alt="Captured photo"
                            fill
                            className="h-full w-full object-cover"
                        />
                    ) : (
                        <video
                            ref={videoRef}
                            autoPlay
                            playsInline
                            muted
                            className="w-full h-full object-cover"
                        />
                    )}

                    {appLoader && (
                        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                            <div className="w-16 h-16 border-4 border-white border-t-transparent rounded-full animate-spin"></div>
                        </div>
                    )}
                </div>
                {(error) && (
                    <div className="mt-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded">
                        {error}
                    </div>
                )}
                {/* Hidden Canvas for Photo Capture */}
                <canvas ref={canvasRef} className="hidden" />
            </div>

            <div className="w-full mx-auto max-w-sm overflow-x-auto">
                <div className="flex gap-x-2 justify-center my-2 min-w-max mx-auto">
                    <GuidelineItem
                        image={sampleImage}
                        status="success"
                        text="Neutral expressions"
                    />
                    <GuidelineItem
                        image={sampleImage}
                        status="error"
                        text="Full image should be in frame"
                    />
                    <GuidelineItem
                        image={sampleImage}
                        status="error"
                        text="Do not upload Blur images"
                    />
                    <GuidelineItem
                        image={sampleImage}
                        status="error"
                        text="No light reflection in image"
                    />
                </div>
            </div>

        </>


    );
}
