import React, {useMemo, useState, useEffect} from "react";
import { useSelector } from "react-redux";
import Header from "/src/views/components/Header/Header.jsx"
import RedirectAnimated from "/src/views/components/RedirectComponent/RedirectAnimation.jsx";
import {Button} from "/components/ui/button.jsx";

export default function EsignRedirect(){
    const {components, step} = useSelector((state)=>state.userSlice);
    const componentConfig = useMemo(()=>{
        let redirectConfig =  components[step]?.data?.message?.ui?.screen?.definition?.attributes;
        return redirectConfig;
    },[step]);

    const [isLoading, setIsLoading] = useState(true);

    setTimeout(() => {
        console.log("Success");
        setIsLoading(false);
    }, 3000); // Simulating network delay

    const handleSubmit = () => {
        if(components[step]?.data?.message?.redirect_behaviour =='SEAMLESS'){
            window.open(components[step]?.data?.message?.href,'_self');
        }
        else{
            window.open(components[step]?.data?.message?.href,'_target');
        }
    };



    let isDisabled = false;

    return (
        <>
            <div className="flex flex-col h-full justify-between">
                <div>
                    <Header />
                </div>
                <div className={'px-[16px] py-[24px] flex-1 overflow-y-auto'}>

                        <div className="flex flex-col gap-[7px]">
                            <h1 className="text-[28px] leading-[40px] font-semibold">
                                 Hang Tight, We're Redirecting You
                            </h1>
                        </div>
                        <p className={'text-[18px]  text-[#00000080]'}>to the eSign, Please wait  and don't refresh</p>

                        <div className="flex justify-center sm:mt-[60px] mt-[88px] mx-[16px]">
                            <RedirectAnimated />
                        </div>
                </div>
                <footer>
                    <div className="flex flex-col gap-[16px] mx-[16px] mb-[24px]">
                        <p className="text-[14px] font-normal leading-[24px] text-[#00000080]">
                            Taking too long don’t worry
                        </p>
                        <Button
                            type="submit"
                            className={`w-full h-[48px] ${
                                isLoading
                                    ? "bg-[#0000001A] cursor-not-allowed text-gray-500 hover:bg-[#0000001A] hover:text-gray-500"
                                    : "bg-black text-white hover:bg-black hover:text-white "
                            }`}
                            variant="outline"
                            onClick={handleSubmit} // Manually trigger the form submission
                        >
                            Click Here
                        </Button>
                    </div>
                    <div className={'px-2 mt-2 py-2 border-t'}>
                        <p className={'text-center text-[12px]'}>Powered by Superflow</p>
                    </div>
                </footer>
            </div>
        </>
    );
}

