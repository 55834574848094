import React, {useEffect, useState, useMemo, useCallback} from "react";
import {Controller, FormProvider, useForm} from "react-hook-form";
import {FormControl, FormItem, FormLabel, FormMessage,} from "/components/ui/form";
import {Input} from "/components/ui/input";
import {Label} from "/components/ui/label.jsx";
import {Button} from "/components/ui/button";
import {InputOTP, InputOTPGroup, InputOTPSeparator, InputOTPSlot,} from "/components/ui/input-otp.jsx";
import SelectInput from "../SelectInputComponent/SelectInput";
import {DateTimePicker} from "../DatePickerComponent/CustomeDatePickerCOmponent";
import {setFormInputFields} from "../../../slices/slices";
import {useDispatch, useSelector} from "react-redux";
import {Loader2} from "lucide-react";
import dayjs from "dayjs";

// ... import other necessary components

const DynamicForm = ({socket, handleNext, components, interfaceBlockId}) => {
    const methods = useForm();
    const {control} = methods;

    const {formInputFields, appLoader,  replayComponentsData, inputError} = useSelector(
        (state) => state.userSlice
    );

    console.log("Replay Com", replayComponentsData);
    const replayFormData = useMemo(()=>{
        return replayComponentsData?.find(item=> item?.data?.interface_block_id ===interfaceBlockId);
    },[replayComponentsData]);

    const [errorMessage, setErrorMessage] = useState(null);
    const [componentArray, setComponentArray] = useState(components);
    const [date, setDate] = useState(null);

    const dispatch = useDispatch();

    const handleSubmit = (e) => {
        e.preventDefault();
        setErrorMessage(null);

        const payload = {
            interaction_type: "FORM_INPUT",
            interface_block_id: interfaceBlockId,
        };

        const isData = componentArray.some(
            (item) => item?.value !== ""
        );

        const payloadComponent = []

        if (isData) {
            componentArray.forEach((item) => {
                if (item?.interaction_type == "FILE_INPUT" && item?.value && item?.file) {
                    payloadComponent.push({
                        interaction_type: item?.interaction_type,
                        data: item?.value || item?.file,
                    });
                } else if (item?.interaction_type == "TEXT_INPUT") {
                    payloadComponent.push({
                        interaction_type: item?.interaction_type,
                        text: item?.value,
                    });
                } else if (item?.interaction_type == "NUMERIC_INPUT") {
                    payloadComponent.push({
                        interaction_type: item?.interaction_type,
                        value: item?.value,
                    });
                } else if (item?.interaction_type == "OTP_INPUT") {
                    payloadComponent.push({
                        interaction_type: item?.interaction_type,
                        value: item?.value,
                    });
                } else if (item?.interaction_type == "SELECT_INPUT") {
                    const filterItem = item?.section?.[0]?.items?.filter((data) =>
                        data?.name == item?.value ? data : null
                    );
                    console.log("filterItem", filterItem, item);
                    payloadComponent.push({
                        interaction_type: item?.interaction_type,
                        title: item?.value,
                        reference_id: filterItem?.[0]?.reference_id,
                    });
                } else if (item?.interaction_type == "DATE_INPUT") {
                    payloadComponent.push({
                        interaction_type: item?.interaction_type,
                        value: item?.value,
                    });
                } else if (item?.interaction_type == "DATETIME_INPUT") {
                    payloadComponent.push({
                        interaction_type: item?.interaction_type,
                        value: item?.value,
                    });
                }
                return undefined;
            });
            Object.assign(payload, {components: payloadComponent})
            handleNext(payload);
        } else {
            setErrorMessage("Please fill all the fields");
        }
    };

    function renderValue(interactionType, defaultValue, index) {
        console.log("defaultValue", replayFormData, interactionType, defaultValue, index);
        if (defaultValue) {
            return defaultValue;
        } else if (replayFormData) {
           const formReplayComponentData = replayFormData?.data?.components;
            console.log("Data", formReplayComponentData[index]);
            if (interactionType == "FILE_INPUT") {
                let obj = {
                    image: `data:${formReplayComponentData[index]?.content_type};base64,${data?.data?.data}`,
                    filename: formReplayComponentData[index]?.filename,
                };

                return obj;
            } else if (interactionType == "NUMERIC_INPUT") {
                return formReplayComponentData[index]?.value;
            } else if (interactionType == "TEXT_INPUT") {
                return formReplayComponentData[index]?.text;
            } else if (interactionType == "OTP_INPUT") {
                return formReplayComponentData[index]?.data?.text;
            } else if (interactionType == "SELECT_INPUT") {
                return formReplayComponentData[index]?.title;
            }
            return undefined;
        }
        return undefined;
    }

    const handleChange = (component, value, index) => {
        console.log("Value", value);
        const tempArray = componentArray?.map((item) => ({
            ...item,
            value: item?.value || "",
            file: item?.file || "",
        }));
        tempArray[index]["value"] = value;
        dispatch(setFormInputFields(tempArray));
    }

    const renderFormControl = (component, field, index) => {
        switch (component.interaction_type) {
            case "TEXT_INPUT":
                return (
                    <Input
                        disabled={replayFormData}
                        required={true}
                        {...field}
                        value={renderValue("TEXT_INPUT", component?.value, index)}
                        onChange={(e) => handleChange(component, e.target.value, index)}
                    />
                );
            case "NUMERIC_INPUT":
                return (
                    <Input
                        disabled={replayFormData}
                        required={true}
                        value={renderValue("NUMERIC_INPUT", component?.value, index)}
                        {...field}
                        type="number"
                        onChange={(e) => handleChange(component, e.target.value, index)}
                    />
                );
            case "OTP_INPUT":
                const messageLength = component?.length || 4;
                return (
                    <InputOTP
                        disabled={replayFormData}
                        maxLength={messageLength || 4}
                        value={renderValue("OTP_INPUT", component?.value, index)}
                        onChange={(value) => {
                            handleChange(component, value, index);
                        }}
                    >
                        {Array.from({length: messageLength}).map((_, i) => {
                            const groupSize =
                                messageLength === 6 ? 3 : Math.ceil(messageLength / 2);

                            return (
                                <React.Fragment key={i}>
                                    <InputOTPGroup>
                                        <InputOTPSlot index={i}/>
                                    </InputOTPGroup>
                                    {(i + 1) % groupSize === 0 && i + 1 < messageLength && (
                                        <InputOTPSeparator>-</InputOTPSeparator>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </InputOTP>
                );
            case "FILE_INPUT":
                return (
                    <Input
                        disabled={replayFormData}
                        required={true}
                        value={renderValue("FILE_INPUT", component?.value, index)}
                        {...field}
                        type="file"
                        onChange={(e) => {
                            const [file] = e.target.files;
                            console.log("file", file);
                            const fileReader = new FileReader();
                            fileReader.readAsDataURL(file);
                            fileReader.onload = () => {
                                handleChange(component, fileReader.result?.split('data:image/jpeg;base64,')[1], index);
                            }
                        }}
                    />
                );
            case "SELECT_INPUT":
                return (
                    <SelectInput
                        disabled={replayFormData}
                        onChange={(value) => handleChange(component, value, index)}
                        component={components}
                        index={index}
                        formInput={true}
                        renderValue={() =>
                            renderValue("SELECT_INPUT", component?.value, index)
                        }
                    />
                );
            case "DATE_INPUT":
                return (
                    <div className={"flex flex-col items-start gap-4"}>
                        <DateTimePicker
                            disabled={replayFormData}
                            required={true}
                            granularity="day"
                            value={renderValue("DATE_INPUT", component?.value, index)}
                            onChange={(value) => {
                                value =   dayjs(value).format("YYYY-MM-DD"),
                                handleChange(component, value, index);
                                ``;
                            }}
                        />
                        {errorMessage && (
                            <span className={"text-red-400"}>{errorMessage}</span>
                        )}
                    </div>
                );
            case "DATETIME_INPUT":
                return (
                    <div className={"flex flex-col items-start  gap-4"}>
                        <DateTimePicker
                            disabled={replayFormData}
                            required={true}
                            value={renderValue("DATETIME_INPUT", component?.value, index)}
                            onChange={(value) => {
                                handleChange(component, value, index);
                            }}
                        />

                        {errorMessage && (
                            <span className={"text-red-400"}>{errorMessage}</span>
                        )}
                    </div>
                );
            case "CAMERA_IMAGE_INPUT":
                return (
                    <Input
                        disabled={replayFormData}
                        required={true}
                        {...field}
                        type="file"
                        accept="image/*"
                        capture="camera"
                    />
                );
            default:
                return <Input    disabled={replayFormData} {...field} />;
        }
    };

    useEffect(() => {
        if (formInputFields.length > 0) {
            setComponentArray(formInputFields);
        }
    }, [formInputFields]);

    console.log("componentArray", componentArray, formInputFields);

    return (
        <FormProvider {...methods}>
                {componentArray.map((component, index) => (
                    <Controller
                        key={index}
                        required={true}
                        control={control}

                        name={component.label.toLowerCase().replace(/\s+/g, "_")}
                        render={({field}) => (
                            <FormItem className="mt-2">
                                <Label>
                                    {component.label}
                                </Label>
                                <FormControl >
                                    {renderFormControl(component, field, index)}
                                </FormControl>

                                <FormMessage/>
                            </FormItem>
                        )}
                    />
                ))}
                {inputError && <span className={"text-red-400 my-2"}>{inputError}</span>}
        </FormProvider>
    );
};

export default DynamicForm;
