import React, {useState, useMemo, useEffect} from "react";
import {useSelector} from "react-redux";
import {Button} from "../../../../components/ui/button.jsx";
import {Input} from "../../../../components/ui/input.jsx";
import {Label} from "../../../../components/ui/label.jsx";
import {Loader2} from "lucide-react";

export default function TextInputComponent(props) {
    const {renderValue, handleNext, elementType, disabledField, handleInput} = props;
    const {components, step, brandingData, appLoader, inputError} = useSelector(state => state.userSlice);
    const [errorMessage, setErrorMessage] = useState('');
    const maxLength = useMemo(() => {
        if (elementType == 'NUMERIC_INPUT') {
            return components?.[step]?.data?.message?.lt || undefined;
        } else {
            return components?.[step]?.data?.message?.max_length || undefined;
        }
    }, [elementType, step]);

    const minLength = useMemo(() => {
        if (elementType == 'NUMERIC_INPUT') {
            return components?.[step]?.data?.message?.gt || undefined;
        } else {
            return components?.[step]?.data?.message?.min_length || undefined;
        }
    }, [elementType, step])

    const handleSubmit = (e) => {
        e.preventDefault();
        const value = components?.[step]?.value;
        console.log("Input length", maxLength, minLength, value?.length);

        if (!value) {
            setErrorMessage(`Input field should not be empty`);
            return;
        } else if (components?.[step]?.data?.message?.validation_regex && !components?.[step]?.data?.message?.validation_regex?.test(value)) {
            setErrorMessage(`Regex error: Value does not match the required pattern. ${components?.[step]?.data?.message?.validation_regex}`);
            return;
        } else if (minLength && value?.length !== minLength) {
            setErrorMessage(`Input value must contain at least ${minLength} characters `);
            return;
        } else if (maxLength && value?.length !== maxLength) {
            setErrorMessage(`Input value must be less than ${maxLength} characters `);
            return;
        }
        const payload = {
            interaction_type: elementType, [elementType == 'NUMERIC_INPUT' ? 'value' : 'text']: value
        }
        handleNext(payload);
    }

    useEffect(()=>{
        setErrorMessage(inputError)
    },[inputError]);

    const placeholder =  useMemo(()=>{
        return components[step]?.data?.message?.ui?.screen?.definition?.attributes?.placeholder || ''
    },[step, components]);



    return (<>
       <Label className={'text-[14px] text-[#000000CC]'}>
            {components?.[step]?.data?.message?.label}
        </Label>
        <Input
            required
            className={`${inputError && 'border-red-300 hover:border-red-300'}`}
            type={'text'}
            autoFocus
            disabled={disabledField}
            maxLength={maxLength}
            minLength={minLength}
            placeholder={placeholder}
            max={maxLength}
            min={minLength}
            value={renderValue(elementType, components?.[step]?.value, components?.[step]?.data?.interface_block_id)}
            onChange={handleInput}
        />
        {errorMessage && <span className={'my-2 text-sm text-red-400'}>{errorMessage}</span>}

            <Button
                className={'hidden w-full'}
                type={'button'}
                disabled={disabledField || appLoader}

                style={{background: brandingData?.accent_colour ? '#' + brandingData?.accent_colour : '#000'}}
                onClick={handleSubmit}>
                {appLoader ? (
                    <>
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        Verifying...
                    </>
                ) : (
                    'Proceed'
                )}
            </Button>
    </>)
}
