'use client'

import {useCallback, useRef, useState} from 'react'
import {RefreshCw, Upload} from 'lucide-react'
import {Button} from "/components/ui/button";
import {useSelector, useDispatch} from "react-redux";
import { callNextEventAPI } from "../../../services/api/api.js";
import { setAppLoader, setFieldValue, setHintData } from "../../../slices/slices.js";
import {Loader2} from "lucide-react";

export default function SignatureComponent(props) {
    const canvasRef = useRef(null)
    const fileInputRef = useRef(null)
    const [hasSignature, setHasSignature] = useState(false)
    const [isDrawing, setIsDrawing] = useState(false)
    const [lastX, setLastX] = useState(0)
    const [lastY, setLastY] = useState(0)
    const [uploadedSignature, setUploadedSignature] = useState(null)
    const [signatureType, setSignatureType] = useState(null);
    const {components, step, brandingData, appLoader} = useSelector(state => state.userSlice);
    const {renderValue, handleNext, handleInput, disabledField} = props;
    const dispatch = useDispatch();


    const startDrawing = (e) => {
        const canvas = canvasRef.current
        if (!canvas) return

        setIsDrawing(true)
        const rect = canvas.getBoundingClientRect()

        if ('touches' in e) {
            setLastX(e.touches[0].clientX - rect.left)
            setLastY(e.touches[0].clientY - rect.top)
        } else {
            setLastX(e.clientX - rect.left)
            setLastY(e.clientY - rect.top)
        }
    }

    const draw = (e) => {
        if (!isDrawing || !canvasRef.current) return

        const canvas = canvasRef.current
        const ctx = canvas.getContext('2d')
        if (!ctx) return

        const rect = canvas.getBoundingClientRect()
        let currentX, currentY

        if ('touches' in e) {
            currentX = e.touches[0].clientX - rect.left
            currentY = e.touches[0].clientY - rect.top
        } else {
            currentX = e.clientX - rect.left
            currentY = e.clientY - rect.top
        }

        ctx.beginPath()
        ctx.moveTo(lastX, lastY)
        ctx.lineTo(currentX, currentY)
        ctx.strokeStyle = '#000'
        ctx.lineWidth = 2
        ctx.lineCap = 'round'
        ctx.stroke()

        setLastX(currentX)
        setLastY(currentY)
        setHasSignature(true)
    }

    const stopDrawing = () => {
        setIsDrawing(false)
    }

    const resetSignature = (e) => {
        e.preventDefault();
        const canvas = canvasRef.current
        if (!canvas) return

        const ctx = canvas.getContext('2d')
        if (!ctx) return

        ctx.clearRect(0, 0, canvas.width, canvas.height)
        setHasSignature(false)
    }

    const handleFileUpload = (event) => {
        event.preventDefault();
        const file = event.target.files?.[0]
        if (!file) return

        if (!file.type.startsWith('image/')) {
            alert('Please upload an image file')
            return
        }

        const reader = new FileReader()
        reader.onload = (e) => {
            const result = e.target?.result
            if (typeof result === 'string') {
                setUploadedSignature(result)
                setSignatureType('upload')
                setHasSignature(true)
            }
        }
        reader.readAsDataURL(file)
    }

    const triggerFileUpload = (e) => {
        e.preventDefault();
        fileInputRef.current?.click()
    }

    const handleFileInput = useCallback((file, content) => {
        // Content type validation
        if (
            components?.[step]?.data?.message?.content_type &&
            file.type !== components?.[step]?.data?.message?.content_type
        ) {
            setError(`Please upload ${components?.[step]?.data?.message?.content_type} format`);
            return;
        }

        console.log("File", file);

        // Prepare form data for API
        const formData = new FormData();
        formData.append("interaction_type", "CAMERA_IMAGE_INPUT");
        formData.append("data", file);
        formData.append("filename", file.name);
        formData.append("content_type", file.type);
        formData.append('interface_block_id', components?.[step]?.data?.interface_block_id);

        // Update components array
        const tempArray = components?.map((item) => ({
            ...item,
            value: item.value || "",
            file: item.file || "",
        }));
        tempArray[step].file = file;
        tempArray[step].value = content;

        // // Dispatch actions
        dispatch(setFieldValue(tempArray));
        dispatch(setAppLoader(true));
        // setCapturedImage(content);
        //
        //
        // Call API
        callNextEventAPI(formData)
            .then((res) => {
                dispatch(setHintData(res.data));
            })
            .catch((err) => setError(err.response?.data?.message));
    }, [components, step, dispatch]);

    return (
        <div className={'space-y-4'}>
            <div className="relative border-2 border-dashed border-gray-300 rounded-lg p-4 bg-gray-50">
                <canvas
                    ref={canvasRef}
                    width={500}
                    height={200}
                    className="w-full h-[200px] bg-white rounded border border-gray-200"
                    onMouseDown={startDrawing}
                    onMouseMove={draw}
                    onMouseUp={stopDrawing}
                    onMouseOut={stopDrawing}
                    onTouchStart={startDrawing}
                    onTouchMove={draw}
                    onTouchEnd={stopDrawing}
                />
                <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
                    {!hasSignature && (
                        <span className="text-gray-400 text-lg">Sign Here</span>
                    )}
                </div>
            </div>

            <div className="flex justify-end">
                <Button
                    variant="outline"
                    size="sm"
                    onClick={resetSignature}
                    className="flex items-center space-x-2"
                    type={'button'}
                >
                    <RefreshCw className="w-4 h-4"/>
                    <span>Reset Signature</span>
                </Button>
            </div>

            <div className="relative">
                <div className="absolute inset-0 flex items-center">
                    <span className="w-full border-t"/>
                </div>
                <div className="relative flex justify-center text-xs uppercase">
                    <span className="bg-white px-2 text-muted-foreground">or</span>
                </div>
            </div>

            <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileUpload}
                accept="image/*"
                className="hidden"
            />
            <Button
                variant="outline"
                className="w-full h-12"
                onClick={triggerFileUpload}
                disabled={signatureType === 'draw' && hasSignature}
            >
                <Upload className="w-5 h-5 mr-2" />
                Upload/Capture Sign
            </Button>
            <div className={'my-4'}>
                 <Button
            className={'w-full'}
            style={{background: brandingData?.accent_colour ? '#' + brandingData?.accent_colour : '#000'}}
            disabled={appLoader || disabledField}
            onClick={(e) => {
                e.preventDefault();
                dispatch(setAppLoader(true));
                const contentType = components?.[step]?.data?.message?.content_type || "image/png";
                const canvas =  canvasRef.current;
                canvas.toBlob((blob) => {
                    const file = new File([blob], `captured_image.${contentType?.split('/')[1]}`, { type: contentType });
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        handleFileInput(file, reader.result);
                    };
                    reader.readAsDataURL(blob);
                }, contentType);

                const payload = {
                    interaction_type: 'SIGNATURE_INPUT', value: components?.[step]?.value
                }

            }}>
                     {appLoader ? (
                         <>
                             <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                             Verifying...
                         </>
                     ) : (
                         'Proceed'
                     )}

        </Button>
            </div>
        </div>
    )
}

