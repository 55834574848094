import "./index.css";
import MainWrapper from "./views/components/MainWrapper/MainWrapper";
import React, { useEffect, useState, useRef, useCallback } from "react";
import initializeSocket from "./socket.js";
import { jwtDecode } from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { getUserInitialize } from "./slices/actions.js";
import { Loader2 } from "lucide-react";
import { useSearchParams } from "react-router-dom";

import {
  getBrandingDetailsAPI,
  getDownloadIconAPI,
  getInterfaceSession,
  getReplaySession,
  getProgressStateAPI,
} from "./services/api/api.js";
import { axiosManager } from "./utils/axiosmanager.js";
import {
  setAppLoader,
  setBrandIcon,
  setBrandingData,
  setComponentData,
  setReplayComponents,
  setSessionData,
  setTraceData,
  setProgressState,
  setLoader, setSocket,
  setStartInterfaceBlockID
} from "./slices/slices.js";
import Error from "./views/components/ErrorComponent/ErrorComponent.jsx";
import ProductionItem from "./views/pages/ProductItem/ProductItem.jsx";

function App() {

  const {
    initialized,
    components,
    replayComponentsData,
    interfaceSession,
    appLoader,
      socket,
  } = useSelector((state) => state.userSlice);
  const { token, error, loader } = useSelector(
    (state) => state.credentialSlice
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const signature = searchParams.get("signature");
  const dispatch = useDispatch();
  const [errorMessage, setError] = useState("");
  const [connected, setConnected] = useState(false);
  const [interfaceSessionData, setInterfaceSessionData] = useState(null);

  const [initialLoad, setInitialLoad] = useState(true);
  const [count, setCount] = useState(0);
  const [isConnected, setIsConnected] = useState(false);

  const timerRef = useRef(null);

  const handleEvent = (eventname, ...args) => {
    console.log("Event Name", eventname);

    if (eventname == "INTERFACE_OUTGOING_MESSAGE") {
      dispatch(setLoader(false));
      if (
        args[0]?.data?.message?.block_type === "ternary_router_block" ||
        args[0]?.data?.message?.block_type === "parse_date_time_block"
      ) {
        return;
      }

      if (
        args[0]?.data?.message?.message_type === "TERMINATE" ||
        args[0]?.data?.message?.message_type === "HALT" ||
        args[0]?.data?.message?.block_current_status === "FAILED" ||
        args[0]?.data?.message?.block_current_status === "DEAD"
      ) {
        dispatch(setAppLoader(false));
        if (
          interfaceSession?.redirect_url ||
          interfaceSessionData?.redirect_url
        ) {
          let status =
            args[0]?.data?.message?.message_type === "TERMINATE"
              ? "completed"
              : args[0]?.data?.message?.message_type === "HALT" ||
                args[0]?.data?.message?.block_current_status === "FAILED" ||
                args[0]?.data?.message?.block_current_status === "DEAD"
              ? "failed"
              : "";
          let redirectUrl='';
          if(interfaceSession?.redirect_url?.includes('?')){
            redirectUrl=`${interfaceSession?.redirect_url || interfaceSessionData?.redirect_url}&status=${status}`
          }else{
            redirectUrl = `${
                interfaceSession?.redirect_url || interfaceSessionData?.redirect_url
            }/?status=${status}`;
          }
          setTimeout(()=>{
            window.open(redirectUrl, "_self");
          },300)
        }
        dispatch(setComponentData(args));
        dispatch(setTraceData(args));
        let closeIframe = () => {
          let url =
            window.location != window.parent.location
              ? document.referrer
              : document.location.href;
          window.parent.postMessage("close-iframe", url);
        };
        closeIframe();
        socket.close();
        localStorage.clear();
        return;
      }

      if (args[0]?.data?.message?.message_type == "TRACE") {
        dispatch(setTraceData(args));
      } else {
        if(args[0]?.data?.message?.message_type=='HINT' && args[0]?.data?.message?.interaction_type=='START_BUTTON'){
          dispatch(setStartInterfaceBlockID(args[0]?.data?.interface_block_id));
        }
        dispatch(setComponentData(args));
        dispatch(setAppLoader(false));
      }
    }
  };

  function addCount() {
    if (count == 3) {
      clearInterval(timerRef.current);
    } else {
      setCount((prevCount) => prevCount + 1);
    }
  }

  useEffect(() => {
    console.log("interfaceSession updated:", interfaceSession);
  }, [interfaceSession]);

  useEffect(() => {
    if (socket) {
      socket.on("connect", () => {
        setConnected(true);
      });

      if (!components?.length && count < 3) {
        timerRef.current = setInterval(addCount, 1000);
      }

      //handle any event
      socket.onAny(handleEvent);

      socket.on("disconnect", () => {
        console.log("Disconnect", socket.id); // undefined
        setIsConnected(false);
      });
    }
    return () => {
      if (count == 3) {
        clearInterval(timerRef.current);
      }
    };
  }, [socket]);

  useEffect(() => {
    if (count == 3) {
      if (!replayComponentsData?.length) {
        if (!components?.length) {
          dispatch(setLoader(false));
          clearInterval(timerRef.current);
          timerRef.current = null;
          setCount(0);
          getReplaySession()
            .then((res) => {
              const traceData = [];
              const componentData = [];
              if (res.data) {
                const { messages = [] } = res.data;

                //Start Button hint
                const startButton = messages?.find(item=> item?.data?.interface_block_type=='on_start_widget_block');
                if(startButton){
                  dispatch(setStartInterfaceBlockID(startButton?.data?.interface_block_id));
                }
                messages.forEach((item) => {
                  if (item?.data?.message?.message_type == "TRACE") {
                    if (
                      item?.data?.message?.block_current_status !==
                        "INITIALIZED" ||
                      item?.data?.message?.block_current_status !==
                        "SUSPENDED" ||
                      item?.data?.message?.block_current_status !== "RESCUED" ||
                      item?.data?.message?.interface_block_type !==
                        "router_block"
                    ) {
                      componentData.push(item);
                      traceData.push(item);
                    }
                  } else {
                    componentData.push(item);
                  }
                });
              }
              console.log("Component Data", componentData);

              dispatch(setReplayComponents(res.data));
              dispatch(setTraceData(traceData));
              dispatch(setComponentData(componentData));
            })
            .catch((err) => setError(err.response?.data?.message))
            .finally(() => dispatch(setLoader(false)));
        }
      }
    }
    return () => {
      if (count == 3) {
        clearInterval(timerRef.current);
      }
    };
  }, [count, replayComponentsData]);

  useEffect(() => {
    if (error) {
      clearInterval(timerRef.current);
      timerRef.current = null;
      dispatch(setLoader(false));
      if (appLoader) {
        dispatch(setAppLoader(false));
      }
    }
    return () => {
      if (count == 3) {
        clearInterval(timerRef.current);
      }
    };
  }, [error]);

  useEffect(() => {
    if (signature && !initialized) {
      dispatch(getUserInitialize(signature));
    }
    return () => {
      if (socket) {
        socket.close();
      }
      if (count == 3) {
        clearInterval(timerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    let newSocket;
    if (token && !socket) {
      newSocket = initializeSocket(token);
      dispatch(setSocket(newSocket));
    }
    if (token) {
      axiosManager.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      // getHintAPI().then(res => {
      //
      // }).catch(err => {
      //     console.log('Error', err.response);
      //     dispatch(setErrorMessage(err.response?.data?.detail))
      // })

      //Progress State
      getProgressStateAPI()
        .then((res) => {
          const progressData = res.data?.progress_state?.block_states;

          dispatch(setProgressState(progressData));
        })
        .catch((err) => {
          setError(err.response?.statusText);
        });

      //Interface Session State
      getInterfaceSession()
        .then((res) => {
          console.log("Response", res.data);
          setInterfaceSessionData(res.data);
          dispatch(setSessionData(res.data));
        })
        .catch((err) => setError(err.response?.statusText));

      //Branding data
      getBrandingDetailsAPI()
        .then((res) => {
          dispatch(setBrandingData(res.data));
        })
        .catch((err) => setError(err.response?.data?.message));

      //Download branding icon
      getDownloadIconAPI()
        .then((res) => res.data)
        .then((res) => {
          const blob = new Blob([res], { type: "image/x-icon" });
          const objectURL = URL.createObjectURL(blob);
          dispatch(setBrandIcon(objectURL));
        })
        .catch((err) => {
          setError(err.response?.data?.message);
        });
    }
    return () => {
      if (count == 3) {
        clearInterval(timerRef.current);
      }
    };
  }, [token]);

  return (
    <div className={'max-w-lg sm:w-full bg-white border mx-auto sm:py-0 py-0 h-full'}>
      {loader ? (
        <div className={"flex h-full flex-col items-center justify-center"}>
          <Loader2 className={"animate-spin"} />
          <p>Please wait...</p>
        </div>
      ) : error ? (
        <Error />
      ) : (
        <ProductionItem/>
      )}
    </div>
  );
}

export default App;
