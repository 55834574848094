import { Button } from "/components/ui/button.jsx";
import finalSuccess from "/public/assets/finalSuccess.svg";

export default function FinalSuccessWizard() {
    return (
        <div className="h-full">
            <div
                className="flex flex-col items-center justify-center pb-[60px] pt-[150px]"
                style={{
                    background:
                        "linear-gradient(to top, rgba(255,255,255,1) 0%, rgba(210,255,211,1) 50%, rgba(255,255,255,1) 100%)",
                }}
            >
                <img src={finalSuccess} alt="Success Illustration" />
            </div>

            <div className="flex flex-col gap-[40px] mx-[16px]">
                <div className="flex flex-col gap-[8px]">
                    <h1 className="text-[24px] leading-[32px] text-center font-semibold">
                        KYC in Progress
                    </h1>
                    <p className="text-[16px] leading-[24px] font-normal text-[#00000080] text-center">
                        Your KYC document is submitted and being processed with KRA.
                    </p>
                </div>
                <Button className="hidden w-full h-[48px]">Done</Button>
            </div>
        </div>
    );
}
