
import { motion } from "framer-motion";
import { useState } from "react";

export default function RedirectAnimated() {
    const [progress, setProgress] = useState(0);

    // Updated path to start from the edge of the circle
    const arrowPath = "M 91 52.25 L 91 94.25 C 91 95.9069 92.3431 97.25 94 97.25 L 180 97.25";

    const pathVariants = {
        initial: { pathLength: 0 },
        animate: {
            pathLength: 1,
            transition: {
                duration: 1.5,
                ease: "linear",
                repeat: Infinity,
                repeatDelay: 0.5
            }
        }
    };

    const ArrowHead = ({ progress }) => {
        const getArrowPosition = (progress) => {
            const totalLength = 94.25 - 52.25 + 180 - 91; // Updated total path length
            const verticalLength = 94.25 - 52.25; // Updated vertical part length
            const horizontalLength = 180 - 91; // Horizontal part length

            if (progress * totalLength <= verticalLength) {
                // Moving down
                return {
                    x: 91,
                    y: 52.25 + progress * totalLength, // Updated starting y-coordinate
                    rotation: 90 // Pointing down
                };
            } else {
                // Moving right
                const horizontalProgress = (progress * totalLength - verticalLength) / horizontalLength;
                return {
                    x: 91 + horizontalProgress * horizontalLength,
                    y: 97.25,
                    rotation: 0 // Pointing right
                };
            }
        };

        const pos = getArrowPosition(progress);

        return (
            <motion.path
                d="M -4 -4 L 4 0 L -4 4"
                fill="none"
                stroke="#059B5A"
                strokeWidth="2"
                style={{
                    translateX: pos.x,
                    translateY: pos.y,
                    rotate: pos.rotation
                }}
            />
        );
    };

    return (
        <svg width="210" height="254" viewBox="0 0 210 254" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4387_12838)">
                <g filter="url(#filter0_d_4387_12838)">
                    <path d="M138 1.25H38V190.25L43 195.25H138" stroke="black" strokeWidth="0.5"/>
                </g>
                <path d="M143 6.25H43M43 195.25H143" stroke="black" strokeWidth="0.5"/>
                <path d="M143 6V79.25" stroke="black" strokeWidth="0.5"/>
                <path d="M143 113.75V195.25" stroke="black" strokeWidth="0.5"/>
                <path d="M38 173.25L0 194.75L90 247.25L180 195.25L143 176.75" stroke="black" strokeOpacity="0.5" strokeWidth="0.5"/>
                <path d="M0 194.75V200.75L90 253.25L180 201.25V195.25" stroke="black" strokeOpacity="0.5" strokeWidth="0.5"/>
                <ellipse cx="93" cy="202.25" rx="58" ry="3" fill="#D9D9D9" fillOpacity="0.5"/>
                <path d="M143 6.25H43V195.25H143" stroke="black" strokeWidth="0.5"/>
                <path d="M38.5 1.25L43 6.25" stroke="black" strokeWidth="0.5"/>
                <path d="M138 1V6.2472" stroke="black" strokeWidth="0.5"/>
                <rect x="85" y="40.25" width="12" height="12" rx="6" fill="white"/>
                <rect x="85" y="40.25" width="12" height="12" rx="6" stroke="#059B5A" strokeWidth="2"/>
                <rect x="197" y="88.25" width="12" height="12" rx="6" fill="white"/>
                <rect x="197" y="88.25" width="12" height="12" rx="6" stroke="#059B5A" strokeWidth="2"/>

                {/* Animated arrow */}
                <motion.path
                    d={arrowPath}
                    variants={pathVariants}
                    initial="initial"
                    animate="animate"
                    stroke="#059B5A"
                    strokeWidth="2"
                    fill="none"
                    onUpdate={({ pathLength = 0 }) => {
                        setProgress(pathLength);
                    }}
                />
                <motion.g
                    initial={{ opacity: 1 }}
                    animate={{ opacity: 1 }}
                >
                    <ArrowHead progress={progress} />
                </motion.g>
            </g>
            <defs>
                <filter id="filter0_d_4387_12838" x="30.75" y="-2" width="110.25" height="200.5" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="2"/>
                    <feGaussianBlur stdDeviation="2.5"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4387_12838"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4387_12838" result="shape"/>
                </filter>
                <clipPath id="clip0_4387_12838">
                    <rect width="210" height="254" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
}
