import React, { useState, useCallback, useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { setFieldValue, setInputError } from "../../../slices/slices.js";
import { useDispatch } from "react-redux";
import { File, X, AlertCircle , FileUp, Trash } from 'lucide-react'
import { Button } from '/components/ui/button'
import { Progress } from '/components/ui/progress'
import { Alert, AlertDescription } from "/components/ui/alert"
import { useFilePicker } from 'use-file-picker';


export default function FileInputComponent(props) {
    const { disabledField } = props;
    const { components, step, appLoader, inputError } = useSelector(state => state.userSlice);
    const [file, setFile] = useState(null)
    const [progress, setProgress] = useState(0)
    const [isDragging, setIsDragging] = useState(false)
    const dispatch = useDispatch();
    const [error, setError] = useState('');

    const { openFilePicker, filesContent, loading, errors } = useFilePicker({
        readAs: 'DataURL',
        accept: 'image/*',
        multiple: true,
        onFilesSelected: ({ plainFiles, filesContent, errors }) => {
            // this callback is always called, even if there are errors
            console.log('onFilesSelected', plainFiles, filesContent, errors);
            handleFile(plainFiles[0]);
        },
        onFilesRejected: ({ errors }) => {
            // this callback is called when there were validation errors
            console.log('onFilesRejected', errors);
        },
        onFilesSuccessfullySelected: ({ plainFiles, filesContent }) => {
            // this callback is called when there were no validation errors
            console.log('onFilesSuccessfullySelected', plainFiles, filesContent);
        },
    });


    const handleFileInput = (file) => {
        if (components?.[step]?.data?.message?.content_type && file.type !== components?.[step]?.data?.message?.content_type) {
            setError(`Please upload ${components?.[step]?.data?.message?.content_type} format`)
            return;
        }

        const tempArray = components?.map(item => ({...item, value: item.value || '', file: item.file || ''}));
        tempArray[step].file = file;
        dispatch(setFieldValue(tempArray));
    }

    const handleDrag = useCallback((e) => {
        e.preventDefault()
        e.stopPropagation()
        if (e.type === 'dragenter' || e.type === 'dragover') {
            const items = Array.from(e.dataTransfer.items)
            if (items.length > 0) {
                const file = items[0]
                const isValid = ['image/jpeg', 'application/pdf', 'application/vnd.ms-excel', 'text/csv'].includes(file.type)

                setIsDragging(true)
                if (!isValid) {
                    setError('File format not valid')
                } else {
                    setError(null)
                }
            }
        } else if (e.type === 'dragleave') {
            setIsDragging(false)
            setError(null)
        }
    }, []);

    const handleDrop = useCallback((e) => {
        e.preventDefault()
        e.stopPropagation()
        setIsDragging(false)

        const files = Array.from(e.dataTransfer.files)
        if (files?.[0]) {
            handleFile(files[0])
        }
    }, []);


    const helpText = useMemo(()=>{
        return components[step]?.data?.message?.ui?.screen?.definition?.attributes?.help_text || inputError;
    },[step])

    const handleFile = (file) => {
        dispatch(setInputError(''))
        console.log("File", file);
        // Check file size (10MB limit)
        if (file.size > 10 * 1024 * 1024) {
           setError('File length should not be exceed 10MB');
            return;
        }

        // Check file type

        if (components[step]?.data?.message?.content_type && !components[step]?.data?.message?.content_type?.includes(file.type) ) {
            const helperText=helpText || 'File format not supported'
            setError(helperText);
            return
        }

        setFile(file)
        // Simulate upload progress
        let progress = 0
        const interval = setInterval(() => {
            progress += 5
            setProgress(progress)
            if (progress >= 100) {
                handleFileInput(file);
                clearInterval(interval)
            }
        }, 100);

    }

    const getBorderColor = () => {
        if (error) return 'border-red-200 bg-red-50'
        if (isDragging) return 'border-green-500 bg-green-50'
        return 'border-gray-300'
    }

    useEffect(()=>{
        if(!file && components[step]?.file){
            setFile(components[step]?.file);
            setProgress(100)
        }
    },[step, components]);

    const fileInputRef = useRef(null);

    const handleClick = useCallback((e) => {
        if(fileInputRef.current){
            console.log("File Ref", fileInputRef.current);
            fileInputRef.current?.click();
        }

    },[fileInputRef]);

    useEffect(()=>{
        setError(inputError);


    },[inputError])

    return (<div className={'space-y-4'}>
        <div
            className={`border-2 border-dashed rounded-lg p-10 text-center ${components[step]?.file && 'cursor-not-allowed'} ${getBorderColor()}`}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
            onClick={()=>{
                if(components?.[step]?.file || components?.[step]?.value){
                    return ;
                }
                openFilePicker();
            }}
        >
            {/*<input*/}
            {/*    ref={fileInputRef}*/}
            {/*    type="file"*/}
            {/*    id="file-input"*/}
            {/*    className="hidden"*/}
            {/*    disabled={appLoader}*/}
            {/*    onChange={(e) => e.target.files?.[0] && handleFile(e.target.files[0])}*/}
            {/*/>*/}
            <div className="flex flex-col items-center gap-3">
                <FileUp size={24} className="text-black" />
                <p className="text-black w-[137px] text-[14px]">
                    Drag & drop or click to choose file
                </p>
            </div>
        </div>

        {/* File Information */}
        <div className="flex justify-between text-sm text-gray-500">
            <p className={''}>Valid formats: jpg,png, jpeg, pdf</p>
            <p>Max: 10MB</p>
        </div>

        {/* Upload Progress */}
        {file && (
            <div className="mt-10 bg-gray-100 p-4 rounded-lg">
                <div className="flex items-center gap-3 mb-2">
                    <File className="h-6 w-6 text-gray-400" />
                    <div className="flex-grow">
                        <div className="font-medium text-[14px]">{file.name}</div>
                        <div className="text-sm text-gray-500">
                            {(file.size / (1024 * 1024)).toFixed(2)}MB
                        </div>
                    </div>
                    <Button size={'icon'} disabled={disabledField} variant={'ghost'} onClick={()=> {
                        const tempArray = components?.map(item => ({...item, value: item.value || '', file: item.file || ''}));
                        tempArray[step].file = '';
                        dispatch(setFieldValue(tempArray));
                        setFile(null);
                    }}>
                        <Trash size={20} className={'text-red-500 '} />
                    </Button>

                </div>
                <Progress value={progress} className="h-2 bg-white" />
            </div>
        )}

        {error && (
            <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 w-full max-w-sm">
                <Alert variant="destructive" className="bg-red-50 border-red-100">
                    <div className={'flex gap-x-2 items-center w-full '}>
                        <AlertCircle className="h-4 w-4" />
                        <AlertDescription className="w-full flex justify-between items-center">
                            {error}
                            <Button
                                variant="ghost"
                                size="icon"
                                className="h-4 w-4 hover:bg-transparent hover:text-red-700 -mr-2"
                                onClick={() => {
                                    setError(null);
                                    dispatch(setInputError(''));
                                }}
                            >
                                <X className="h-4 w-4" />
                            </Button>
                        </AlertDescription>
                    </div>
                </Alert>
            </div>
        )}
    </div>)
}
