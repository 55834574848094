import React from "react";
import {useSelector} from "react-redux";
import {replace} from "lodash";

export default function ElementComponent() {
    const {components, step} = useSelector(state => state.userSlice);
    return (
        <h3 className={`${components?.[step]?.data?.interface_block_type == 'flash_message_widget_block' ? 'font-[600] text-[24px] sm:text-[16px]' : 'text-[16px]'}`}>
            {replace(components?.[step]?.data?.message?.text, /\*/g, '')}
        </h3>)
}
