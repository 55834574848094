import React, { useMemo, useState } from "react";
import { Label } from "/components/ui/label.jsx";
import { Input } from "/components/ui/input.jsx";
import Header from "../Header/Header.jsx";
import PANScreenEmailIcon from "/public/icon/screen-icons/PANScreenEmailIcon.jsx";
import { MdErrorOutline } from "react-icons/md";
import FooterComp from "../../components/Footer/Footer.jsx";
import PANSuccessTick from "/public/icon/screen-icons/PANSuccessTick.jsx";
import PANFailedCrossIcon from "/public/icon/screen-icons/PANFailedCrossIcon.jsx";
import {SheetDescription, SheetTitle} from "/components/ui/sheet.jsx";
import {panRegex} from "../../../utils/allRegex.jsx";
import cross from "/public/assets/cross.svg";
import tick from "/public/assets/tick.svg";
import { Button } from "/components/ui/button.jsx";
import { Sheet, SheetContent } from "/components/ui/sheet.jsx";
import {useSelector} from "react-redux";
import waiting from "/public/assets/waiting.svg";
import WaitingAnimation from "./WaitingAnimation.jsx";

export default function WaitingScreen() {
    const [open, setOpen] = useState(false);
    const {
        step,
        components,
        progressState,
        socket,
        formInputFields,
        appLoader,
        startInterfaceBlockID,
        brandingData
    } = useSelector((state) => state.userSlice);
    const [error, setError] = useState("");


    return (
        <div className="flex flex-col h-full justify-between">
            <div>
                <Header />
            </div>
            <div className={'px-[16px] py-[24px] flex-1 overflow-y-auto'}>

                <div className="flex flex-col gap-[7px]">
                    <h1 className="text-[28px] leading-[40px] font-semibold">
                        Awaiting Bank Confirmation
                    </h1>
                </div>
                <p className={'text-[14px] leading-[20px] text-[#00000080] my-[16px]'}>This will only take a moment. Please wait while we process the confirmation</p>
                <div className="flex justify-center mt-[88px] mx-[16px]">
                    <WaitingAnimation />
                </div>
            </div>
            <footer>
                <div className="flex flex-col gap-[16px] mx-[16px] mb-[24px]">
                    <p className="text-[14px] font-normal leading-[24px] text-[#00000080]">
                        Taking too long don’t worry
                    </p>
                </div>
                <div className={'px-2 mt-2 py-2 border-t'}>
                    <p className={'text-center text-[12px]'}>Powered by Superflow</p>
                </div>
            </footer>
        </div>
    );
}
