"use client"

import { motion } from "framer-motion"

export default function AnimatedWaiting() {
    return (
        <svg width="300" height="300" viewBox="0 0 182 183" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M41.1942 101L1 123.5L91 176L181 124L139.495 101" stroke="black" strokeWidth="0.5"/>
            <path d="M1 123.5V129.5L91 182L181 130V124" stroke="black" strokeWidth="0.5"/>
            <path d="M149.75 66C149.75 100.668 122.988 128.75 90 128.75C57.0123 128.75 30.25 100.668 30.25 66C30.25 31.3325 57.0123 3.25 90 3.25C122.988 3.25 149.75 31.3325 149.75 66Z" fill="#D9D9D9" stroke="#B7B7B7" strokeWidth="0.5"/>
            <rect x="30.25" y="3.25" width="119.5" height="119.5" rx="59.75" fill="white"/>
            <rect x="30.25" y="3.25" width="119.5" height="119.5" rx="59.75" stroke="black" strokeWidth="0.5"/>

            <g clipPath="url(#clip0_4345_15102)">
                <rect x="43" y="16" width="94" height="94" rx="47" fill="white"/>
                {/* Needle Group - This will be animated */}
                <motion.g
                    animate={{
                        rotate: [-45, 45, -45] // Swing between -45 and 45 degrees
                    }}
                    transition={{
                        duration: 2,
                        repeat: Infinity,
                        ease: "easeInOut"
                    }}
                    style={{
                        originX: 0.5,
                        originY: 1, // Set origin to bottom
                        transformOrigin: "90px 63px" // Center-bottom point of the needle
                    }}
                >
                    <rect x="84" y="57" width="12" height="12" rx="6" fill="white"/>
                    <rect x="84" y="57" width="12" height="12" rx="6" stroke="black" strokeWidth="2"/>
                    <path
                        d="M91 57L86.5 57.5L80 39.5L80.5 36L84 38L91 57Z"
                        fill="black"
                        stroke="black"
                    />
                </motion.g>
                <path d="M50.5 110H155.5" stroke="black" strokeWidth="0.5"/>
                <path d="M43 93H148" stroke="black" strokeWidth="0.5"/>
            </g>
            <rect x="43.25" y="16.25" width="93.5" height="93.5" rx="46.75" stroke="black" strokeWidth="0.5"/>
            <defs>
                <clipPath id="clip0_4345_15102">
                    <rect x="43" y="16" width="94" height="94" rx="47" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}

