import React, {useCallback, useEffect, useMemo, useState} from "react";
import Header from "../components/Header.jsx";
import {accountHolderNameRegex, accountNumberRegex, ifscCodeRegex,} from "../../../../utils/allRegex.jsx";
import {Button} from "/components/ui/button.jsx";
import {Label} from "/components/ui/label.jsx";
import cross from "/public/assets/cross.svg";
import tick from "/public/assets/tick.svg";
import FeedbackCard from "../components/FeedbackCard.jsx";
import {QRCodeSVG as QRCode} from 'qrcode.react';
import phonepay from "/public/assets/phonepay.png";
import whatsapp from "/public/assets/whatsapp.png";
import googlepay from "/public/assets/gpay.png";
import bhimpay from "/public/assets/bhim.png";
import paytm from "/public/assets/paytm.png";
import list from "/public/assets/list.svg"
import {useSelector} from "react-redux";
import {MoveRight} from "lucide-react";
import WaitingScreen from "/src/views/components/WaitingScreen/WaitingScreen.jsx";

export default function ReversePennyDrop() {
    const [paymentMethod, setPaymentMethod] = useState("paytm");
    const [isWaiting, setIsWaiting] = useState(false);
    const [formData, setFormData] = useState({
        accountNumber: "",
        confirmAccountNumber: "",
        ifscCode: "",
        accountHolderName: "",
    });
    const {
        step,
        components,
        progressState,
        socket,
        formInputFields,
        appLoader,
        startInterfaceBlockID,
        brandingData
    } = useSelector((state) => state.userSlice);

    const [paymentInfo, setPaymentInfo] = useState([
        {
            title: 'Paytm',
            icon: paytm,
            value: 'paytm',
        },
        {
            title: 'Google Pay',
            icon: googlepay,
            value: 'gpay'
        },
        {
            title: 'PhonePe',
            icon: phonepay,
            value: 'phonepe'
        },
        {
            title: 'BHIM',
            icon: bhimpay,
            value: 'bhim'
        },
        {
            title: 'WhatsApp',
            icon: whatsapp,
            value: 'whatsapp'
        },
        {
            title: 'Other UPI',
            value: 'others',
            icon: list
        }
    ])

    const [accountNumber, setAccountNumber] = useState("");
    const [confirmAccountNumber, setConfirmAccountNumber] = useState("");
    const [ifscCode, setIfscCode] = useState("");
    const [accountHolderName, setAccountHolderName] = useState("");
    const [isMobile, setIsMobile] = useState(false);

    const [open, setOpen] = useState(false);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isFindUPI, setIsFindUPI] = useState(false);
    const [userUPIID, setUserUPIID] = useState("");
    const [upiError, setUpiError] = useState("");

    const validateForm = () => {
        const newErrors = {};

        // Validate account number
        if (!accountNumber) {
            newErrors.accountNumber = "Account number is required";
        } else if (!accountNumberRegex.test(accountNumber)) {
            newErrors.accountNumber =
                "Account number must be between 10-18 digits and numeric";
        }

        // Validate confirm account number
        if (accountNumber !== confirmAccountNumber) {
            newErrors.confirmAccountNumber = "Account numbers do not match";
        }

        // Validate IFSC code
        if (!ifscCode) {
            newErrors.ifscCode = "IFSC code is required";
        } else if (!ifscCodeRegex.test(ifscCode)) {
            newErrors.ifscCode = "Invalid IFSC code format";
        }

        // Validate account holder name
        if (!accountHolderName) {
            newErrors.accountHolderName = "Account holder name is required";
        } else if (!accountHolderNameRegex.test(accountHolderName)) {
            newErrors.accountHolderName =
                "Account holder name must contain only letters and spaces";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const isDisabled = useMemo(
        () =>
            Object.keys(errors).length > 0 ||
            Object.values(formData).some((field) => !field),
        [errors, formData]
    );

    const handleFocus = () => {
        setUpiError("");
    };

    const SuccessFeedback = () => (
        <FeedbackCard
            image={tick}
            title="Aadhaar Verified Successfully"
            message="Your Aadhaar has been successfully verified! Proceed to the next step."
            buttonText="Proceed"
            onButtonClick={() => setOpen(false)}
        />
    );

    const ErrorFeedback = () => (
        <FeedbackCard
            image={cross}
            title="Aadhaar Verification Failed"
            message="Aadhaar verification failed. Please check the details and try again."
            buttonText="Retry"
            onButtonClick={() => setOpen(false)}
        />
    );

    const handleSubmit = (e) => {
        if (paymentMethod) {
            const paymentLink = components[step]?.data?.message?.list;
        }
    }

    useEffect(() => {
        if (components?.length && components[step]?.data?.message?.interaction_type === 'LIST_MESSAGES') {
            const reversePennyDropItem = components[step]?.data?.message?.values;
        }
    }, [step, components]);

    const isAndroid = useMemo(() => {
        let isAndroid = /Android/i.test(navigator.userAgent);
        if (isAndroid) {
            return true;
        }
        return false;
    }, []);

    const renderPaymentMode = useCallback((item) => (

        <Button type={'button'}
                className={"flex h-[64px] items-center justify-between p-4 rounded-lg "}
                variant={'outline'}
                onClick={(e) => {
                    e.preventDefault();
                    const selectedNode = components[step]?.data?.message?.values?.find(key => key.text === item.title);
                    console.log("Selected", selectedNode, item.title);
                    if (selectedNode) {
                        setIsWaiting(true);
                        window.open(selectedNode?.parameters?.href, '_target');
                    }
                }}
        >
            <div className="flex items-center gap-3">
                <div className={'flex justify-center items-center h-[32px] w-[32px]'}>
                    <img
                        src={item.icon}
                        alt="Paytm"

                        className={'w-full object-contain'}
                    />
                </div>

                <Label htmlFor="paytm" className="text-[16px] font-[400]">{item.title}</Label>
            </div>
            <MoveRight className={'text-[14px] text-gray-800'}/>

        </Button>
    ), []);

    const others = useMemo(() => {
        return components[step]?.data?.message?.values?.find(item => item.text == 'Other UPI');
    }, [step, components]);


    useEffect(() => {
        const checkMobile = () => {
            const mobileCheck = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
            setIsMobile(mobileCheck);
        };

        checkMobile();
        window.addEventListener('resize', checkMobile);

        return () => window.removeEventListener('resize', checkMobile);
    }, []);






    return (
        <div className="flex flex-col h-full justify-between">
            <div>
                <Header/>
                <hr/>
            </div>
            <div className={'relative px-[16px] py-[24px] flex-1 overflow-y-auto'}>
                <div>
                    {/* Title Section */}
                    <div className="flex flex-col gap-2 ">
                        <h1 className="text-[24px] font-[600]">Reverse Penny Drop</h1>
                        <p className="hidden text-sm text-gray-500">
                            Scan QR code or select UPI method for reverse penny verification.
                        </p>
                    </div>

                    {/* Payment Method QR */}
                    {!isMobile &&
                        <div className="bg-[#0000000D] my-10 py-[24px]   rounded-lg ">
                            <QRCode
                                size={200}
                                className={'mx-auto my-auto h-full'}
                                level={'L'}
                                value={others?.parameters?.href || ''}
                            />
                            <div className={'pt-[16px]'}>
                                <p className={'text-center font-[400] text-gray-500 text-[14px]'}>
                                    Scan QR for reverse pennydrop
                                </p>
                            </div>
                        </div>}

                </div>

                <div className="flex flex-col gap-4 my-[24px]  ">
                    <div className={'bg-[#F6F9FFFF] py-[16px] flex items-start gap-x-2 justify-center relative my-6'}>

                        <div>
                            <p className={'flex justify-center  items-center gap-x-1 text-[16px] text-muted-foreground font-[500]'}>
                         <span className="relative flex size-3">
  <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-sky-400 opacity-75"></span>
  <span className="relative inline-flex size-3 rounded-full bg-sky-500"></span>
</span>Awaiting payment confirmation</p>
                            <span className={'text-[12px] text-center text-gray-600'}>Please complete the payment using your UPI App</span>
                        </div>

                    </div>

                    <Label className={' leading-[20px]'}>{components[step]?.data?.message?.text}</Label>
                    {paymentInfo.map(item => {
                        if (isAndroid && item.title == 'Other UPI') {
                            return (
                                renderPaymentMode(item)
                            )
                        }
                        else if(!isAndroid && item.title!=='Other UPI') return renderPaymentMode(item)
                        return null
                    })}
                </div>

            </div>
            <div>
                <footer className="">
                    <div className={'px-2 mt-2 py-2 border-t'}>
                        <p className={'text-center text-[12px]'}>Powered by Superflow</p>
                    </div>
                </footer>
            </div>

        </div>
    );
}
